import { hangarAirplanes } from 'features/hangar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import planeListImg from 'assets/img/plane-list-stub.png';

type FormAirplanesSelectProps = {
  value: any;
  id?: string;
  airlinesOnly?: boolean;
  label: string;
  labelKey?: string;
  onChange: (v: any) => void;
};

export const FormAirplanesSelect = ({
  label,
  value,
  onChange,
  id,
  labelKey,
  airlinesOnly,
}: FormAirplanesSelectProps) => {
  const airplanes = useSelector(hangarAirplanes);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let opts = [];

    airplanes?.map((air) => {
      const opt = { id: air.id, label: `${air.name} ${air.aircraftReg ? `[${air.aircraftReg}]` : ``}`, ...air };

      if (!airlinesOnly || (airlinesOnly && air.airline)) {
        opts.push(opt);
      }
    });

    setOptions(opts);
  }, [airplanes]);

  return (
    <Typeahead
      id={id}
      labelKey={labelKey}
      filterBy={() => true}
      onChange={onChange}
      options={options}
      defaultSelected={value}
      placeholder="Select airplane..."
      renderMenuItemChildren={(item: any) => (
        <Row>
          <Col md={2}>
            <img src={planeListImg} alt={item.name} width="100%" />
          </Col>
          <Col md>
            <h5>{item.name}</h5>
            {item.aircraftReg && (
              <p className="m-0">
                <strong>{`${item.aircraftReg}`}</strong>
              </p>
            )}
            <p className="m-0">
              {`${item.codeIcao}`} / {`${item.airline}`}
            </p>
          </Col>
        </Row>
      )}
      renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => (
        <FloatingLabel controlId={id} label={label}>
          <Form.Control
            value={value as string}
            {...inputProps}
            ref={(node) => {
              inputRef(node);
              referenceElementRef(node);
            }}
          />
        </FloatingLabel>
      )}
    />
  );
};

import { loadTrackMapData } from 'features/track';
import { useDispatch } from 'react-redux';

let connectionOpened = false;

const connectToSocket = (dispatch: any) => {
  if (connectionOpened) return;
  const ws = new WebSocket(`${process.env.REACT_APP_SOCKET_ADDR?.trim() || 'wss://radar-ws.flysim.io'}/socket.io/`);
  connectionOpened = true;
  ws.onmessage = (e) => {
    try {
      const msg = JSON.parse(e.data);

      if (msg.event === 'radar') {
        dispatch(loadTrackMapData(msg.data));
      }
    } catch (e) {
      console.error('onmessage error: ', e);
    }
  };
  ws.onclose = () => {
    console.warn('Web socket reconnect');
    connectToSocket(dispatch);
  };
  return ws;
};

export const useSocket = () => connectToSocket(useDispatch());

import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Polyline, TileLayer, Tooltip, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { TrackMapProps, usePlannedRoute, plannedRouteOptions, useTrackRoute, trackRouteOptions } from './helpers';
import { Features } from './Features';
import { RotatedMarker } from './RotatedMarker';
import { iconPlane } from './icons/Plane';

export const TrackMap = ({ navlog, tracklog, center, active, zoom = 5 }: TrackMapProps) => {
  const geodataRef = useRef(null);
  const plannedRoute = usePlannedRoute(navlog);
  const trackRoute = useTrackRoute(tracklog);
  const markers: Array<React.ReactNode> = [];
  const [zoomLevel, setZoomLevel] = useState(zoom);

  const MapEvents = () => {
    const map = useMapEvents({
      zoomend: () => {
        setZoomLevel(map.getZoom());
      },
    });
    return null;
  };

  useEffect(() => {
    if (geodataRef.current) {
      geodataRef.current.clearLayers().addData(plannedRoute.features);
    }
  }, [plannedRoute]);

  if (active) {
    active.forEach((f) => {
      markers.push(
        <RotatedMarker
          key={f.id}
          position={[f.lat, f.lon]}
          icon={iconPlane(zoomLevel)}
          rotationAngle={f.track}
          rotationOrigin="center"
        >
          <Tooltip direction="bottom">
            {f.code} {f.track}&deg; {Math.round(f.alt)}ft
          </Tooltip>
        </RotatedMarker>,
      );
    });
  }

  if (!center) {
    return null;
  }

  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '600px', width: '100%' }} key={center[0].toString()}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapEvents />
      {markers}
      {trackRoute && <Polyline pathOptions={trackRouteOptions} positions={trackRoute} />}
      {plannedRoute && <Polyline pathOptions={plannedRouteOptions} positions={plannedRoute.poly} />}
      {plannedRoute && <Features georef={geodataRef} data={plannedRoute.features} />}
    </MapContainer>
  );
};

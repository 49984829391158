import { Form } from 'react-bootstrap';
import SocialAuthButtons from './SocialAuthButtons';

const LoginForm = () => {
  return (
    <Form>
      <SocialAuthButtons />
    </Form>
  );
};

export default LoginForm;

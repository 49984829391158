import classNames from 'classnames';
import Flex from 'components/common/Flex';
import dayjs from 'dayjs';
import { summary as accountSummary } from 'features/account';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import relativeTime from 'dayjs/plugin/relativeTime';
import { NavLink } from 'react-router-dom';
import styles from './ActiveFlight.module.scss';

dayjs.extend(relativeTime);

export const ActiveFlight = () => {
  const { activeFlight } = useSelector(accountSummary);

  return (
    <>
      {activeFlight && (
        <NavLink to={`/flight/${activeFlight.id}`} className={styles.activeFlight}>
          <Card className="h-md-100">
            <Card.Header className="pb-0">
              <h6 className="mb-0 mt-2">Active</h6>
            </Card.Header>
            <Card.Body as={Flex} alignItems="end" justifyContent="between">
              <div className={classNames('mb-1 text-700 fw-normal lh-1')}>
                {activeFlight.origin && (
                  <>
                    <h5>
                      {activeFlight.origin} &gt; {activeFlight.destination || 'N/A'}
                    </h5>
                    <span>Started {dayjs.utc(activeFlight.createdAt).fromNow()}</span>
                  </>
                )}
                {activeFlight && !activeFlight.origin && (
                  <>
                    <h4>Freeflight</h4>
                    <span>Started {dayjs.utc(activeFlight.createdAt).fromNow()}</span>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </NavLink>
      )}
      {!activeFlight && (
        <Card className="h-md-100">
          <Card.Header className="pb-0">
            <h6 className="mb-0 mt-2">Active</h6>
          </Card.Header>
          <Card.Body as={Flex} alignItems="end" justifyContent="between"></Card.Body>
        </Card>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { summary as accountSummary } from 'features/account';
import { useSelector } from 'react-redux';
import { directionsRouteOptions } from './helpers';

export const FlightsMap = () => {
  const { directions } = useSelector(accountSummary);
  const [poly, setPoly] = useState([]);

  useEffect(() => {
    const localPoly = [];

    directions?.map((row) => {
      localPoly.push([
        [row.originLat, row.originLon],
        [row.destinationLat, row.destinationLon],
      ]);
    });

    setPoly(localPoly);
  }, [directions]);

  return (
    directions && (
      <Card>
        <Card.Header className={'bg-light'}>
          <h5>Flown Routes</h5>
        </Card.Header>
        <Card.Body className="p-0">
          <MapContainer center={[27.4110201, 3.056715]} zoom={2} scrollWheelZoom={false} style={{ height: '500px' }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {poly.map((p) => (
              <Polyline pathOptions={directionsRouteOptions} positions={p} />
            ))}
          </MapContainer>
        </Card.Body>
      </Card>
    )
  );
};

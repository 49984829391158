import React from 'react';

export const DataBlock = ({ label, value, compact = false, nomargin = false }) =>
  compact ? (
    <>
      <h6>{label}</h6>
      <div
        className={`${!nomargin && `mb-4`}`}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '80%' }}
      >
        {value}
      </div>
    </>
  ) : (
    <dl>
      <dt>{label}:</dt>
      <dd style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</dd>
    </dl>
  );

import { FormAirframesSelect } from 'components/common/combobox/Airframes';
import { FormAirlinesSelect } from 'components/common/combobox/Airlines';
import { loadAirplanes } from 'features/hangar';
import { useState } from 'react';
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { hangarTransport } from 'transport';

type PlaneProps = {
  name: string;
  airline: string;
  aircraftReg: string;
  location: string;
  airframe: string;
};

export const HangarAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingPlaneByReg, setLoadingPlaneByReg] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [values, setValues] = useState<PlaneProps>({} as any);
  const { name, airframe, airline, aircraftReg, location } = values;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const onClose = () => {
    navigate('/hangar/airplanes');
  };

  const onChange = (field: string, e: any) => {
    let data = { ...values };
    if (e?.target) {
      data[field] = e.target.value;
    } else {
      data[field] = e;
    }
    setValues(data);
  };

  const findPlane = () => {
    setLoadingPlaneByReg(true);
    hangarTransport
      .findAirplaneByReg(aircraftReg)
      .then((payload: any) => {
        setValues({
          name: payload.name,
          aircraftReg: payload.aircraftReg,
          location: payload.location,
          airframe: payload.iata,
          airline: payload.airline,
        });
        setLoadingPlaneByReg(false);
        setAllowEdit(true);
      })
      .catch((e) => {
        console.error(e);
        setLoadingPlaneByReg(false);
        setAllowEdit(true);
      });
  };

  const onSave = () => {
    if (!aircraftReg || aircraftReg.length === 0) {
      setError('Registration is required');
      return;
    }
    if (!name || name.length === 0) {
      setError('Name is required');
      return;
    }
    if (!airframe || airframe.length === 0) {
      setError('Airframe is required');
      return;
    }
    if (!airline || airline.length === 0) {
      setError('Airline is required');
      return;
    }
    setSaving(true);
    hangarTransport
      .saveFleet({
        name,
        aircraftReg,
        airline,
        location,
        codeIata: airframe,
      })
      .then(() => {
        setSaving(false);
        hangarTransport.loadFleet().then((payload) => {
          dispatch(loadAirplanes(payload));
        });
        navigate(`/hangar/airplanes?added`);
      })
      .catch((e) => {
        setSaving(false);
        setError('Unable to add airplane due to error');
      });
  };

  return (
    <Modal show centered onHide={onClose} dialogClassName="add-hangar-wizard-modal">
      <Modal.Header closeButton>Airplane</Modal.Header>
      <Modal.Body className="p-4">
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group className="mb-3" controlId="details.Registration">
          <FloatingLabel label="Registration" className="mb-3">
            <Form.Control
              placeholder="N456FLY"
              value={aircraftReg}
              onChange={onChange.bind(this, 'aircraftReg')}
              onBlur={findPlane}
            />
          </FloatingLabel>
        </Form.Group>
        {loadingPlaneByReg && (
          <div
            className="spinner-border position-absolute top-0 end-0"
            style={{ marginTop: '70px', marginRight: '35px' }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <Form.Group className="mb-3" controlId="details.Name">
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control
              placeholder="Airbus A320"
              disabled={!allowEdit}
              value={name}
              onChange={onChange.bind(this, 'name')}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Location">
          <FloatingLabel label="Location" className="mb-3">
            <Form.Control
              placeholder="KSFO"
              disabled={!allowEdit}
              value={location || ''}
              onChange={onChange.bind(this, 'location')}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Code">
          <FormAirframesSelect
            id="airframe"
            label="Airframe"
            value={airframe}
            disabled={!allowEdit}
            onChange={onChange.bind(this, 'airframe')}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Airline">
          <FormAirlinesSelect
            id="airline"
            label="Airline"
            value={airline}
            disabled={!allowEdit}
            onChange={onChange.bind(this, 'airline')}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSave.bind(this)} disabled={saving}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

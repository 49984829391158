import React, { useState } from 'react';
import { Col, Row, Table, TableProps } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';

type TableGridColProps = {
  testAutomationId?: string;
  label: string;
  handler: (row: any) => React.ReactNode;
  colProps?: any;
};

type TableGridProps = {
  columns: TableGridColProps[];
  data: Array<any>;
  tableProps: TableProps;
  size?: number;
};

export const TableGrid = ({ columns, data, tableProps, size = 15 }: TableGridProps) => {
  const [page, setPage] = useState(1);
  const startWith = (page - 1) * size;
  const endWith = startWith + size;

  return (
    <React.Fragment>
      <Table {...tableProps}>
        <thead>
          <tr>
            {columns.map((col, idx) => (
              <th key={`col-${idx}`} scope="col">
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(startWith, endWith).map((row, idx) => {
            return (
              <tr key={`row-${idx}`} data-test-automation-id={`row-${idx}`} className="align-middle">
                {columns.map((col, cidx) => (
                  <td
                    key={`row-cell-${idx}-${cidx}-${col.testAutomationId}`}
                    data-test-automation-id={`row-cell-${idx}-${col.testAutomationId}`}
                    {...col.colProps}
                  >
                    {col.handler(row)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col xs={2}>Total: {data.length}</Col>
        <Col>
          <PaginationControl
            page={page}
            between={4}
            total={data.length}
            limit={size}
            changePage={(page) => {
              setPage(page);
            }}
            ellipsis={1}
          />
        </Col>
        <Col xs={2}></Col>
      </Row>
    </React.Fragment>
  );
};

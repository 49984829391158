import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

export const SpinnerLoader = ({ label }: { label: string }) => (
  <Modal show centered backdrop="static" keyboard={false}>
    <Modal.Body>
      <div className="d-flex justify-content-center align-items-center mb-5 mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{label}</span>
        </Spinner>
      </div>
      <p className="text-center">{label}</p>
    </Modal.Body>
  </Modal>
);

import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Polyline, TileLayer, GeoJSON } from 'react-leaflet';
import { LatLngExpression, LatLngTuple, circleMarker } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Feature } from 'geojson';

export interface Waypoint {
  ident: string;
  alt: number;
  lon: number;
  lat: number;
  name: string;
  type: string;
  via: any;
}

interface FlyMapProps {
  waypoints: Waypoint[];
  distance: number;
}

const detectZoom = (distance: number): number => {
  let zoom = 5;
  if (distance < 500) {
    zoom = 6;
  }
  if (distance > 3000) {
    zoom = 3;
  }
  if (distance > 4500) {
    zoom = 2;
  }
  if (distance > 6000) {
    zoom = 1;
  }
  return zoom;
};

export const RouteMap = ({ waypoints, distance }: FlyMapProps) => {
  const limeOptions = {
    color: '#B49DA0',
    dashArray: '5 5',
    weight: 1,
  };
  const [features, setFeatures] = useState<Feature[]>([]);
  const [center, setCenter] = useState<LatLngTuple>();
  const [zoom, setZoom] = useState(5);
  const [poly, setPoly] = useState<LatLngExpression[]>([]);
  const geodataRef = useRef(null);

  let apt: Waypoint[] = [];

  useEffect(() => {
    const geoFeatures = [];
    const localPoly = [];

    waypoints.map((row) => {
      if (row.type === 'APT') {
        apt.push(row);
      }
      localPoly.push([row.lat, row.lon]);
      geoFeatures.push({
        type: 'Feature',
        properties: {
          ...row,
        },
        geometry: {
          type: 'Point',
          coordinates: [row.lon, row.lat],
        },
      });
    });
    setCenter([(apt[0].lat + apt[1].lat) / 2, (apt[0].lon + apt[1].lon) / 2]);
    setZoom(detectZoom(distance));
    setFeatures(geoFeatures);
    setPoly(localPoly);
  }, []);

  useEffect(() => {
    if (geodataRef.current) {
      geodataRef.current.clearLayers().addData(features);
    }
  }, [features]);

  return (
    center &&
    zoom && (
      <MapContainer center={center} zoom={zoom} style={{ height: '600px', width: '100%' }} key={center[0].toString()}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Polyline pathOptions={limeOptions} positions={poly} />
        <GeoJSON
          ref={geodataRef}
          data={features as any}
          pointToLayer={(feature, latlng) => {
            const label = String(feature.properties.ident);
            return circleMarker(latlng, { radius: 1 })
              .bindTooltip(label, { permanent: true, opacity: 0.7 })
              .openTooltip();
          }}
        />
      </MapContainer>
    )
  );
};

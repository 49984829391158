import { PageHeader } from 'components/common/PageHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import './OFP.m.scss';
import { useSelector } from 'react-redux';
import { hangarAirplanes } from 'features/hangar';
import { Waypoints } from './Waypoints';
import { RouteMap } from 'components/livemap/Route';
import Divider from 'components/common/Divider';
import { AirportCard } from 'components/common/AirportCard';
import { plannerTransport } from 'transport';
import { formatUnit } from 'utils';
import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { Loading } from 'components/common/Loading';
import { SpinnerLoader } from 'components/common/loaders/Spinner';
import { DataBlock } from 'components/common/DataBlock';

dayjs.extend(duration);
dayjs.extend(utc);

const findPlaneById = (planes, id) => {
  return planes.filter((p) => id === p.id)[0];
};

export const OFP = () => {
  const [plan, setPlan] = useState(null);
  const [strartFlightInProgress, setStrartFlightInProgress] = useState(false);
  const [origDest, setOrigDest] = useState(null);
  const airplanes = useSelector(hangarAirplanes);
  const origin = window.localStorage.getItem('PASSCARGO_OFP_ORIGIN');
  const destination = window.localStorage.getItem('PASSCARGO_OFP_DESTINATION');
  const airplane = findPlaneById(airplanes, parseInt(window.localStorage.getItem('PASSCARGO_OFP_PLANE_ID') || '0'));
  const { ofp_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (ofp_id) {
      plannerTransport
        .fetchFlightPlan(ofp_id, window.localStorage.getItem('PASSCARGO_OFP_TYPE'))
        .then((payload) => setPlan(payload))
        .catch((e) => {
          console.error(e);
          if (e.statusCode === 404) {
            navigate(`/mcdu/plan`);
          }
        });
    } else {
      const ofp = window.localStorage.getItem('PASSCARGO_OFP');
      if (ofp) {
        setPlan(JSON.parse(ofp));
      }
    }
  }, []);

  useEffect(() => {
    plannerTransport.lookupAirports([origin, destination]).then((payload) => setOrigDest(payload));
  }, [origin, destination]);

  const onStartFlight = useCallback(() => {
    setStrartFlightInProgress(true);
    plan.fid = airplane.id;
    plannerTransport.startFlight(plan).then((res: any) => {
      let int = setInterval(async () => {
        const status: any = await plannerTransport.getFlightStatus(res.result);
        if (status.result !== -1) {
          clearInterval(int);
          setStrartFlightInProgress(false);
          navigate(`/flight/${res.result}`);
        }
      }, 5000);
    });
  }, [plan, airplane]);

  const actionButtons = plan && (
    <div style={{ marginTop: '15px' }}>
      <Button onClick={onStartFlight}>Start Flight</Button>
    </div>
  );

  return (
    <>
      <PageHeader
        title="Pre-Flight Briefing"
        description="Take time to review the route"
        className="mb-3"
        buttons={actionButtons}
      />

      {origDest && (
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col sm={5}>{origDest[origin] ? <AirportCard item={origDest[origin]} /> : 'N/A'}</Col>
              <Col sm={2} style={{ marginTop: '1%', textAlign: 'center', fontSize: '250%' }}>
                &rarr;
              </Col>
              <Col sm={5}>{origDest[destination] ? <AirportCard item={origDest[destination]} /> : 'N/A'}</Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      {!plan && <Loading />}

      {plan && (
        <Card className="mb-3">
          <Card.Body>
            {plan.route && <div className="route">{plan.route}</div>}
            <Row className="loadSheet">
              <Col xs={6}>
                <DataBlock label="Airplane" value={plan.aircraftName} />
                <DataBlock label="Flight Number" value={`${plan.airline}${plan.flightNumber}`} />
                <DataBlock label="Airframe" value={plan.aircraftReg} />
              </Col>
              <Col xs={6}>
                <DataBlock label="CI" value={plan.costIndex || '7'} />
                <DataBlock label="Cruise" value={`${plan.cruiseAltitude} ft`} />
                <DataBlock label="Distance" value={`${plan.distance} nm`} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      {plan && plan.times && (
        <Card className="mb-3">
          <Card.Body>
            <Row className="loadSheet">
              <Col xs={6}>
                <DataBlock
                  label="Departure"
                  value={dayjs(plan.times.estOut * 1000)
                    .utc()
                    .format('DD MMM YY HH:mm UTC')}
                />
                <DataBlock
                  label="Air Time"
                  value={`${dayjs.duration(plan.times?.estEnroute, 'seconds').format('HH:mm')}`}
                />
              </Col>
              <Col xs={6}>
                <DataBlock
                  label="Arrival"
                  value={dayjs(plan.times.estIn * 1000)
                    .utc()
                    .format('DD MMM YY HH:mm UTC')}
                />
                <DataBlock
                  label="Block Time"
                  value={`${dayjs.duration(plan.times?.estBlock, 'seconds').format('HH:mm')}`}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      {plan && plan.fuel && (
        <Card className="mb-3">
          <Card.Body>
            <Row className="loadSheet">
              <Col xs={6}>
                <DataBlock label="Total Burn" value={formatUnit(plan.fuel.burn, plan.units)} />
                <DataBlock label="Block Fuel" value={formatUnit(plan.fuel.ramp, plan.units)} />
                <DataBlock label="Reserve" value={formatUnit(plan.fuel.reserve, plan.units)} />
                <DataBlock label="Taxi" value={formatUnit(plan.fuel.taxi, plan.units)} />
                <DataBlock label="Take Off" value={formatUnit(plan.fuel.takeoff, plan.units)} />
                <DataBlock label="Landing" value={formatUnit(plan.fuel.landing, plan.units)} />
              </Col>
              <Col xs={6}>
                <DataBlock label="Passengers" value={plan.weights.pax} />
                <DataBlock label="Cargo" value={formatUnit(plan.weights.cargo, plan.units)} />
                <DataBlock label="Payload" value={formatUnit(plan.weights.payload, plan.units)} />
                <DataBlock label="EZFOW" value={formatUnit(plan.weights.estZFW, plan.units)} />
                <DataBlock label="ETOW" value={formatUnit(plan.weights.estTOW, plan.units)} />
                <DataBlock label="ELDW" value={formatUnit(plan.weights.estLDW, plan.units)} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      {plan && (
        <Card className="mb-3">
          <Card.Body>
            <RouteMap waypoints={plan.navlog} distance={plan.distance} />
            <Divider />
            <Waypoints waypoints={plan.navlog} />
          </Card.Body>
        </Card>
      )}

      {plan && plan.preview && (
        <Card className="mb-3">
          <Card.Body>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Briefing Preview</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: plan.preview }} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Body>
        </Card>
      )}

      {strartFlightInProgress && (
        <SpinnerLoader label="We are waiting while PassCargo accepts this flight in simulator..." />
      )}
    </>
  );
};

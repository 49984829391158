import React from 'react';
import classNames from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';
import { Background } from './Background';
import corner4 from 'assets/img/illustrations/corner-4.png';
import createMarkup from 'helpers/createMarkup';

type Props = {
  title: string;
  preTitle?: string;
  titleTag?: string;
  description?: string;
  col?: any;
  image?: string;
  className?: string;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
};

export const PageHeader = ({
  title,
  preTitle,
  titleTag = 'h3',
  description,
  image = corner4,
  col = { lg: 8 },
  children,
  buttons,
  ...rest
}: Props) => {
  const TitleTagCmp = titleTag as any;
  return (
    <Card {...rest}>
      <Background
        image={image}
        className="bg-card"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
        }}
      />
      <Card.Body className="position-relative">
        <Row>
          <Col {...col}>
            {preTitle && <h6 className="text-600">{preTitle}</h6>}
            <TitleTagCmp className="mb-0">{title}</TitleTagCmp>
            {description && (
              <p
                className={classNames('mt-2', {
                  'mb-0': !children,
                })}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            )}
            {children}
          </Col>
          {buttons && <Col style={{ display: 'flex', justifyContent: 'end' }}>{buttons}</Col>}
        </Row>
      </Card.Body>
    </Card>
  );
};

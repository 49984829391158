import { AirportCard } from 'components/common/AirportCard';
import { PageHeader } from 'components/common/PageHeader';
import { flightData } from 'features/flights';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { accountTransport, plannerTransport, trackTransport } from 'transport';
import { loadFlightById } from 'features/flights';
import { Loading } from 'components/common/Loading';
import { TrackMap } from 'components/livemap/Track';
import { detectZoom } from 'components/livemap/helpers';
import { trackFlightsData } from 'features/track';
import { Timeline } from './Timeline';
import { FuelBrief } from './FuelBrief';
import { InfoBrief } from './InfoBrief';
import { ScheduleBrief } from './ScheduleBrief';
import './Overview.m.scss';
import { Weather } from './Weather';
import { findById } from './utils';
import { ChartAltProfile, ChartSpeedProfile } from './ChartProfile';
import { loadSummary } from 'features/account';

export const FlightOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flight = useSelector(flightData);
  const { flightId } = useParams();
  const [completed, setCompleted] = useState(true);
  const activeFlights = useSelector(trackFlightsData) as any;
  const [markers, setMarkers] = useState([]);
  const [tracklog, setTracklog] = useState([]);
  const [posCenter, setPosCenter] = useState(null);

  const doRefresh = () => {
    accountTransport.loadFlightById(flightId).then((payload: any) => {
      if (!completed && payload.sts === 'Completed') {
        // reset active flight
        accountTransport.loadSummary().then((payload) => dispatch(loadSummary(payload)));
      }
      setCompleted(payload.sts === 'Completed');
      dispatch(loadFlightById(payload));
    });
  };

  useEffect(() => {
    doRefresh();

    let refresh = null;

    if (flight?.sts !== 'Completed') {
      refresh = setInterval(() => {
        doRefresh();
      }, 30000);
    }

    return () => {
      clearInterval(refresh);
    };
  }, [flightId]);

  useEffect(() => {
    if (!flight) {
      return;
    }

    if (flight.ori_apt) {
      if (flight.des_apt) {
        setPosCenter([(flight.ori_apt.lat + flight.des_apt.lat) / 2, (flight.ori_apt.lon + flight.des_apt.lon) / 2]);
      } else {
        setPosCenter([flight.ori_apt.lat, flight.ori_apt.lon]);
      }
    }

    const loMarkers = [];
    const loTracklog = [...tracklog];

    if (flight.sts !== 'Completed') {
      const f: any = findById(activeFlights, flightId);

      if (f) {
        loMarkers.push(f);
        loTracklog.unshift({
          lat: f.lat,
          lng: f.lon,
        });
      }
      setMarkers(loMarkers);
      setTracklog(loTracklog);
    }
  }, [activeFlights]);

  useEffect(() => {
    trackTransport
      .loadTrack(flightId)
      .then((payload: any) => setTracklog(payload))
      .catch((e) => console.error(e));
  }, []);

  const onCancelFlight = useCallback(() => {
    plannerTransport.cancelFlight(flightId).then((res: any) => {
      navigate(`/?flightCanceled`);
    });
  }, []);

  const onCompleteFlight = useCallback(() => {
    const req = {
      traveled_distance: flight.trdis,
    } as any;

    plannerTransport.completeFlight(flightId, req).then((res: any) => {
      window.location.reload();
    });
  }, []);

  const actionButtons =
    flight && (flight.sts === 'TaxiIn' || flight.sts === 'Parking') ? (
      <div style={{ marginTop: '15px' }}>
        <Button variant="warning" onClick={onCompleteFlight}>
          Complete Flight
        </Button>
      </div>
    ) : (
      <div style={{ marginTop: '15px' }}>
        <Button variant="warning" onClick={onCancelFlight}>
          Cancel Flight
        </Button>
      </div>
    );

  return (
    <>
      <PageHeader
        title="Flight Overview"
        description={completed ? 'Logged flight brief' : 'Active flight summary'}
        className="mb-3"
        buttons={flight && !completed ? actionButtons : null}
      />

      {!flight && <Loading />}

      {flight && (
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col sm={5}>
                {flight.ori_apt && <AirportCard item={flight.ori_apt} />}
                {!flight.ori_apt && <h5>N/A</h5>}
              </Col>
              <Col sm={2} style={{ marginTop: '1%', textAlign: 'center', fontSize: '250%' }}>
                &rarr;
              </Col>
              <Col sm={5}>
                {flight.des_apt && <AirportCard item={flight.des_apt} />}
                {!flight.des_apt && <h5>N/A</h5>}
              </Col>
            </Row>
            {flight.sts !== 'Completed' && (
              <Row>
                <Col sm={6}>{flight.ori && <Weather ident={flight.ori} />}</Col>
                <Col sm={6}>{flight.des && <Weather ident={flight.des} />}</Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      )}

      {flight && flight.route && (
        <Card className={`mb-3 card-route`}>
          <Card.Body>{flight.route}</Card.Body>
        </Card>
      )}

      {flight && (
        <Row>
          <Col xs={7} className="pb-3">
            <Card className="h-100">
              <Card.Header className={'bg-light'}>
                <h5>Timeline</h5>
              </Card.Header>
              <Card.Body className="px-sm-2 px-md-4 px-lg-4 px-xxl-4">
                <Timeline flightId={flightId} />
              </Card.Body>
            </Card>
          </Col>
          <Col xs>
            <Card className="mb-3">
              <Card.Header className={'bg-light'}>
                <h5>Schedule</h5>
              </Card.Header>
              <Card.Body>
                <ScheduleBrief />
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Header className={'bg-light'}>
                <h5>Information</h5>
              </Card.Header>
              <Card.Body>
                <InfoBrief />
              </Card.Body>
            </Card>
            {(flight.weights || flight.fuel) && (
              <Card className="mb-3">
                <Card.Header className={'bg-light'}>
                  <h5>Fuel & Weights</h5>
                </Card.Header>
                <Card.Body>
                  <FuelBrief />
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      )}

      {flight && (
        <Card className="mb-3">
          <Card.Body className="p-0">
            <TrackMap
              navlog={flight.navlog}
              tracklog={tracklog}
              active={markers}
              center={posCenter}
              zoom={detectZoom(flight.distance)}
            />
          </Card.Body>
        </Card>
      )}

      {flight && flight.sts === 'Completed' && (
        <Card className="mb-3">
          <Card.Body className="p-0">
            <ChartAltProfile source={tracklog} />
          </Card.Body>
        </Card>
      )}

      {flight && flight.sts === 'Completed' && (
        <Card className="mb-3">
          <Card.Body className="p-0">
            <ChartSpeedProfile source={tracklog} />
          </Card.Body>
        </Card>
      )}

      {flight && flight.preview && (
        <Card className="mb-3">
          <Card.Body>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Briefing Preview</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: flight.preview }} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { doLogout } from 'transport/base';

export const Logout = () => {
  useEffect(() => {
    doLogout();
    window.location.href = '/login';
  }, []);

  return null;
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'components/common/Loading';
import { PageHeader } from 'components/common/PageHeader';
import { historyFlights, loadFlights } from 'features/flights';
import { Alert, Badge, Card, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import { formatNumber } from 'utils';
import { accountTransport } from 'transport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Route, Routes } from 'react-router-dom';
import { FlightOverview } from 'components/flights/Overview';
import { TableGrid } from 'components/table/TableGrid';

const columns = [
  {
    label: '#',
    handler: (r: any) => <Link to={r.flightId}>{r.callsign}</Link>,
    colProps: {
      width: '10%',
      className: 'text-nowrap',
    },
  },
  {
    label: 'Route',
    handler: (r: any) => (
      <Link to={r.flightId}>
        {!r.origin && 'Freeflight'}
        {r.origin && (
          <span>
            {`${r.origin} `}&gt;{` ${r.destination || 'N/A'}`}
          </span>
        )}
      </Link>
    ),
    colProps: {
      className: 'text-nowrap',
    },
  },
  {
    label: 'Date',
    colProps: {
      width: '20%',
      className: 'text-nowrap',
    },
    handler: (r: any) => dayjs(r.createdAt).format('DD MMM YY HH:mm'),
  },
  {
    label: 'Duration',
    colProps: {
      width: '10%',
      className: 'text-end',
    },
    handler: (r: any) => (
      <>
        {r.duration > 0 && dayjs.duration(r.duration, 'seconds').format('HH:mm:ss')}
        {!r.duration && <>&mdash;</>}
      </>
    ),
  },
  {
    label: 'Distance',
    colProps: {
      width: '10%',
      className: 'text-end',
    },
    handler: (r: any) => `${formatNumber(r.distance)}nm`,
  },
  {
    label: 'Rate',
    colProps: {
      width: '10%',
      className: 'text-center',
    },
    handler: (r: any) => (
      <>
        {r.rate && r.rate.indexOf('A') > -1 && <Badge bg="success">{r.rate}</Badge>}
        {r.rate && r.rate.indexOf('B') > -1 && <Badge bg="warning">{r.rate}</Badge>}
        {r.rate && r.rate.indexOf('C') > -1 && <Badge bg="danger">{r.rate}</Badge>}
        {!r.rate && <>&mdash;</>}
      </>
    ),
  },
];

export const Logbook = () => {
  const dispatch = useDispatch();
  const logbook = useSelector(historyFlights);

  useEffect(() => {
    accountTransport.loadFlights().then((payload) => {
      dispatch(loadFlights(payload));
    });
  }, []);

  const LogBookItems = (
    <>
      <PageHeader title="Logbook" description="You will never lose your flights" className="mb-3" />

      {logbook == null && <Loading />}

      {logbook && (
        <Card>
          <Card.Body>
            {logbook.length === 0 && (
              <Alert variant="info" className="border-2 d-flex align-items-center mb-0">
                <div className="bg-info me-3 icon-item">
                  <FontAwesomeIcon icon="info-circle" className="text-white fs-3" />
                </div>
                <p className="mb-0 flex-1">No flights found. It's time to fly high!</p>
              </Alert>
            )}
            {logbook.length > 0 && (
              <TableGrid
                columns={columns}
                data={logbook}
                tableProps={{ hover: true, striped: true, responsive: true }}
              />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );

  return (
    <>
      <Routes>
        <Route path="/" element={LogBookItems} />
        <Route path="/:flightId" element={<FlightOverview />} />
      </Routes>
    </>
  );
};

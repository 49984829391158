export const findById = (flights: any, id: string | undefined) => {
  if (!flights) {
    return null;
  }

  let f: any = null;
  flights.every((it: any) => {
    if (it.id === id) {
      f = it;
      return false;
    }
    return true;
  });

  if (f) {
    return {
      poly: [f.lat, f.lon],
      ...f,
    };
  }
  return null;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Customer } from 'helpers/types';
import 'whatwg-fetch';

const PASSCARGO_SESSION = 'PSC_SESSION_ID';
const PASSCARGO_CUSTOMER = 'PSC_CUSTOMER';

const API_URL = '/restapi';

export const FLY_AUTH_API_ROOT_URL = `${API_URL}/fly-auth/v1.0`;
export const FLY_API_ROOT_URL = `${API_URL}/fly-api/v1.0`;
export const FLY_TRACK_ROOT_URL = `${API_URL}/tracker/v1.0`;

let CSRF = '';

type SessionToken = {
  token_type: string;
  access_token: string;
};

const handleRequest = (resource: string, method = 'GET', params: object | null = null, skipAuthFailure = false) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': CSRF,
  };
  // get auth header
  const s: string | null = window.localStorage.getItem(PASSCARGO_SESSION);
  if (s) {
    const st: SessionToken = JSON.parse(s) as SessionToken;
    headers['Authorization'] = st.token_type + ' ' + st.access_token;
  }
  return fetch(resource, {
    method: method,
    headers,
    credentials: 'include',
    body: params ? JSON.stringify(params) : null,
  }).then((res) => {
    if (res.status >= 200 && res.status < 400) {
      if (res.status === 204) {
        return {};
      }

      const contentType = res.headers.get('content-type');

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return res.json();
      } else {
        return res.text();
      }
    } else if (!skipAuthFailure && res.status === 401) {
      window.localStorage.removeItem(PASSCARGO_SESSION);
      window.location.href = '/login/expired?to=' + window.location.pathname;
    } else {
      const err: any = new Error(res.statusText);
      err.statusCode = res.status;
      err.response = res;
      throw err;
    }
  });
};

export const getLoggedInfo = (): Promise<Customer> => {
  return get(`${FLY_AUTH_API_ROOT_URL}/account-info`) as Promise<Customer>;
};

export const setToken = (token: SessionToken) => {
  window.localStorage.setItem(PASSCARGO_SESSION, JSON.stringify(token));
};

export const setSessionData = async (customer: Customer) => {
  window.localStorage.setItem(PASSCARGO_CUSTOMER, JSON.stringify(customer));
};

export const getToken = () => {
  handleRequest('/check').then((res) => {
    CSRF = res as string;
  });
};

export const doLogout = () => {
  window.localStorage.removeItem(PASSCARGO_SESSION);
  window.localStorage.removeItem(PASSCARGO_CUSTOMER);
};

export const getAuthSkip = (resource: string) => {
  return handleRequest(resource, 'GET', null, true);
};

export const get = (resource: string) => {
  return handleRequest(resource, 'GET');
};

export const post = (resource: string, params: object) => {
  return handleRequest(resource, 'POST', params);
};

export const put = (resource: string, params: object) => {
  return handleRequest(resource, 'PUT', params);
};

export const del = (resource: string) => {
  return handleRequest(resource, 'DELETE');
};

import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { summary as accountSummary } from 'features/account';
import CountUp from 'react-countup';

export const TraveledDistance = () => {
  const summary = useSelector(accountSummary);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Traveled Distance</h6>
      </Card.Header>
      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2 className={classNames('mb-1 text-700 fw-normal lh-1')}>
            <CountUp end={summary.traveledDistance} duration={2.75} suffix="nm" />
          </h2>
        </div>
      </Card.Body>
    </Card>
  );
};

import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

export const Loading = () => (
  <Container>
    <Row className="justify-content-md-center mt-10">
      <Col md="auto">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  </Container>
);

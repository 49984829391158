import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { PageHeader } from 'components/common/PageHeader';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FaqBasicItem = ({ faq, isLast = false }) => {
  return (
    <>
      <h6>
        <Link to="#!">
          {faq.title}
          <FontAwesomeIcon icon="caret-right" className="ms-2" />
        </Link>
      </h6>
      <p className="fs--1 mb-0">{faq.description}</p>
      {!isLast && <hr className="my-3" />}
    </>
  );
};

export const Faq = () => (
  <>
    <PageHeader
      title="Frequently Asked Questions"
      description="Below you'll find answers to the questions we get asked the most about FlySim EFB"
      className="mb-3"
    />

    <Card>
      <Card.Body>
        <FaqBasicItem
          faq={{
            title: 'Data is not updated on Overlay',
            description:
              'You can restart application. Just close the FlySim EFB window and start it again. Do not fotget to connect to the Simulator again.',
          }}
        />
        <FaqBasicItem
          faq={{
            title: 'Flight phase is not updated',
            description:
              'There is an option "Fix Phase" in the Active Flight section in the FlySim EFB. Choose phase that more suitable for you.',
          }}
        />
        <FaqBasicItem
          faq={{
            title: 'Restart and Fix Phase are not helped',
            description:
              'Go to {user}\\AppData\\Roaming\\FlySim EFB and delete files: state.json, track.json and restart the application.',
          }}
          isLast
        />
      </Card.Body>
      <Card.Footer>
        <Flex alignItems={'center'}>
          <h5 className="d-inline-block me-3 mb-0 fs--1">If you still have a problem. Let us know!</h5>
          <Button
            variant="falcon-default"
            size="sm"
            onClick={() => window.open('https://www.facebook.com/flyaroundsim')}
          >
            Go to Feedback
          </Button>
        </Flex>
      </Card.Footer>
    </Card>
  </>
);

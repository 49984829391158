import { hangarAirframes } from 'features/hangar';
import { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';

import { FloatingLabel, Form } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';

type FormAirframesSelectProps = {
  value: any;
  id?: string;
  label: string;
  labelKey?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange: (v: any) => void;
};

export const FormAirframesSelect = ({
  label,
  value,
  onChange,
  id,
  labelKey,
  readOnly = false,
  disabled = false,
}: FormAirframesSelectProps) => {
  const airframes = useSelector(hangarAirframes);
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let opts = [];
    let slctd = null;

    airframes?.map((air) => {
      const opt = { id: air.iata, label: `${air.icao} - ${air.name}` };

      opts.push(opt);

      if (air.iata === value) {
        slctd = opt;
      }
    });

    setOptions(opts);
    setSelected(slctd ? [slctd] : []);
  }, [value]);

  return (
    <>
      {!readOnly && (
        <Typeahead
          id={id}
          labelKey={labelKey}
          onChange={(o: any) => {
            onChange(o.length > 0 ? o[0].id : o);
          }}
          options={options}
          selected={selected}
          disabled={disabled}
          renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => {
            return (
              <FloatingLabel controlId={id} label={label}>
                <Form.Control
                  value={value as string}
                  {...inputProps}
                  ref={(node) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                />
              </FloatingLabel>
            );
          }}
        />
      )}
      {selected && readOnly && (
        <FloatingLabel label={label} className="mb-3">
          <Form.Control plaintext readOnly value={selected[0].label} />
        </FloatingLabel>
      )}
    </>
  );
};

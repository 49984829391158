import { PageHeader } from 'components/common/PageHeader';
import pkg from '../../../package.json';

export const DesktopComponent = () => {
  return (
    <>
      <PageHeader
        title="PassCargo Desktop"
        description="Plan, track, log your flights. Works only on Windows and compatible with Microsoft Flight Simulator, X Plane 11/12 and Prepar3D"
        className="mb-3"
      />
      <div className="card overflow-hidden" style={{ width: '20rem' }}>
        <div className="card-img-top">
          <img
            className="img-fluid"
            src="https://passcargo.app/static/media/passcargo-active-flight.891c0a1e9409821d1349.png"
            alt="PassCargo Desktop"
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">v24.4.0 / Sep 20th, 2024</h5>
          <ul>
            <li>new design</li>
            <li>re-designed FS integration</li>
            <li>re-designed live map</li>
            <li>Simbrief integration</li>
          </ul>
          <a className="btn btn-primary btn-sm" href={pkg.downloadUrl} target="_blank">
            <span className="fas fa-windows me-1" data-fa-transform="shrink-3"></span>Download
          </a>
        </div>
      </div>
    </>
  );
};

import { PageHeader } from 'components/common/PageHeader';
import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';

export const Timetable = () => {
  return (
    <>
      <PageHeader
        title="Timetable"
        description="Do you want to fly using real-time flight schedule?"
        className="mb-3"
      />
      <Card>
        <Card.Header>Find Route</Card.Header>
        <Card.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>From</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Airframe</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Airline</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <div className="float-end">
            <Button>Search</Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

import * as base from './base';

export const GetLoginLink = (provider: string, state: string | null = null) => {
  return base.get(`${base.FLY_AUTH_API_ROOT_URL}/${provider}/auth/code${state ? '?state=' + state : ''}`);
};

export const oauthCallback = (provider: string, query: string) => {
  return base.get(`${base.FLY_AUTH_API_ROOT_URL}/${provider}/callback${query}`);
};

export const validateDevice = (code: string) => {
  return base.get(`${base.FLY_AUTH_API_ROOT_URL}/device/auth/${code}`);
};

export const loadSettings = () => {
  return base.get(`${base.FLY_API_ROOT_URL}/cockpit/settings`);
};

export const loadSummary = () => {
  return base.get(`${base.FLY_API_ROOT_URL}/cockpit/summary`);
};

export const saveSettings = (values: any) => {
  let settings = [] as any;
  Object.entries(values).map((entry) => {
    settings.push({
      section: entry[0],
      settings: JSON.stringify(entry[1]),
    });
  });
  return base.post(`${base.FLY_API_ROOT_URL}/cockpit/settings`, { settings });
};

export const loadFlights = () => {
  return base.get(`${base.FLY_API_ROOT_URL}/cockpit/flights/logged`);
};

export const loadFlightById = (id: string) => {
  return base.get(`${base.FLY_API_ROOT_URL}/cockpit/flights/view/${id}`);
};

export const loadStatistic = () => {
  return base.get(`${base.FLY_API_ROOT_URL}/cockpit/stats`);
};

export const getLoggedFlightTmelines = (id: string) =>
  base.get(`${base.FLY_API_ROOT_URL}/cockpit/flights/timelines/${id}`);

export const metar = (ident: string) => base.get(`${base.FLY_API_ROOT_URL}/share/metar/${ident}`);

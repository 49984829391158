import { FormAirframesSelect } from 'components/common/combobox/Airframes';
import { FormAirlinesSelect } from 'components/common/combobox/Airlines';
import { hangarAirplanes } from 'features/hangar';
import React, { useState } from 'react';
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { hangarTransport } from 'transport';

const findById = (id: number, planes: Array<any>) => {
  const pl = planes.filter((p) => id === p.id);

  return pl.length > 0 ? pl[0] : null;
};

export const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const airplanes = useSelector(hangarAirplanes);
  const [values, setValues] = useState(findById(parseInt(id), airplanes));
  const { name, codeIata, airline, aircraftReg, location } = values;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const onClose = () => {
    navigate('/hangar/airplanes');
  };

  const onSave = () => {
    if (!aircraftReg || aircraftReg.length === 0) {
      setError('Registration is required');
      return;
    }
    if (!name || name.length === 0) {
      setError('Name is required');
      return;
    }
    if (!codeIata || codeIata.length === 0) {
      setError('Airframe is required');
      return;
    }
    if (!airline || airline.length === 0) {
      setError('Airline is required');
      return;
    }
    setSaving(true);
    hangarTransport
      .saveFleet({
        id,
        name,
        aircraftReg,
        airline,
        location,
        codeIata,
      })
      .then(() => {
        setSaving(false);
        navigate(`/hangar/airplanes?updated`);
      })
      .catch((e) => {
        setSaving(false);
        setError('Unable to add airplane due to error');
      });
  };

  const onChange = (field: string, e: any) => {
    let data = { ...values };
    if (e.target) {
      data[field] = e.target.value;
    } else {
      data[field] = e[0];
    }
    setValues(data);
  };

  return (
    <Modal show onHide={() => onClose()} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Airplane Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group className="mb-3" controlId="details.Name">
          <FloatingLabel label="Name" className="mb-3">
            <Form.Control placeholder="Airbus A320" value={name} onChange={onChange.bind(this, 'name')} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Location">
          <FloatingLabel label="Location" className="mb-3">
            <Form.Control placeholder="KSFO" value={location || ''} onChange={onChange.bind(this, 'location')} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Code">
          <FormAirframesSelect
            id="codeIata"
            label="Airframe"
            value={codeIata}
            readOnly={id !== undefined}
            onChange={onChange.bind(this, 'codeIata')}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Airline">
          <FormAirlinesSelect
            readOnly={id !== undefined}
            id="airline"
            label="Airline"
            value={airline}
            onChange={onChange.bind(this, 'airline')}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="details.Location">
          <FloatingLabel label="Registration" className="mb-3">
            {aircraftReg && <Form.Control plaintext readOnly defaultValue={aircraftReg} />}
            {!aircraftReg && (
              <Form.Control placeholder="REG" value={aircraftReg || ''} onChange={onChange.bind(this, 'aircraftReg')} />
            )}
          </FloatingLabel>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => navigate(`/mcdu/logbook/?plane=${id}`)}>
          Flights
        </Button>
        <Button onClick={onSave.bind(this)}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

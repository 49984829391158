import { createSlice } from '@reduxjs/toolkit';

type StateProps = {
  hangar: any;
};

export const hangarSlice = createSlice({
  name: 'hangar',
  initialState: {
    airplanes: [],
    airframes: [],
  },
  reducers: {
    loadAirplanes: (state, action) => {
      state.airplanes = action.payload;
    },
    loadAirframes: (state, action) => {
      state.airframes = action.payload;
    },
  },
});

export const { loadAirplanes, loadAirframes } = hangarSlice.actions;

export const hangarAirplanes = (state: StateProps) => state.hangar.airplanes;
export const hangarAirframes = (state: StateProps) => state.hangar.airframes;

export default hangarSlice.reducer;

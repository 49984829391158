import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormAirplanesSelect } from 'components/common/combobox/Airplanes';
import { FormOriginDestSelect } from 'components/common/combobox/OriginDest';
import Divider from 'components/common/Divider';
import { SpinnerLoader } from 'components/common/loaders/Spinner';
import { PageHeader } from 'components/common/PageHeader';
import dayjs from 'dayjs';
import React, { useState, useRef } from 'react';
import { Alert, Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { plannerTransport } from 'transport';
import { md5 } from 'utils';

const sbworkerurl = 'https://www.simbrief.com/ofp/ofp.loader.api.php';
const sbworkerid = 'SBworker';
const sbcallerid = 'SBcaller';
const sbworkerstyle = 'width=600,height=315';
var ofp_id: string;
var sbworker: any;
var SBloop: number;

const hiddenField = (name: string, value: string, form: HTMLFormElement) => {
  let input = document.createElement('input');
  let append = true;

  if (document.getElementById(`fld${name}`)) {
    input = document.getElementById(`fld${name}`) as any;
    append = false;
  }

  input.setAttribute('value', value);
  if (append) {
    input.setAttribute('id', `fld${name}`);
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    form.appendChild(input);
  }
};

export const NewFlight = () => {
  const [values, setValues] = useState<any>({});
  const { airplane, origin, destinaton, registration, callsign, flightNumber, route } = values;
  const [plannerLoading, setPlannerLoading] = useState(false);
  const [plannerError, setPlannerError] = useState(null);
  const [importError, setImportError] = useState(null);
  const navigate = useNavigate();
  const regRef = useRef(null);

  const onChange = (field: string, e: any) => {
    let data = { ...values };
    if (e.target) {
      data[field] = e.target.value;
    } else {
      if (field === 'airplane' && !registration) {
        regRef.current.value = e[0].aircraftReg;
        data['registration'] = e[0].aircraftReg;
        regRef.current.focus();
      }
      data[field] = e;
    }
    setValues(data);
  };

  const requestFilled = airplane && origin && destinaton;

  const setPayloadOFP = (type: string, ofpId: string, payload: any) => {
    window.localStorage.setItem('PASSCARGO_OFP_TYPE', type);
    if (payload) {
      window.localStorage.setItem('PASSCARGO_OFP', JSON.stringify(payload));
    } else {
      window.localStorage.removeItem('PASSCARGO_OFP');
    }
    window.localStorage.setItem('PASSCARGO_OFP_ORIGIN', origin[0].icao);
    window.localStorage.setItem('PASSCARGO_OFP_DESTINATION', destinaton[0].icao);
    window.localStorage.setItem('PASSCARGO_OFP_PLANE_ID', airplane[0].id);
    setPlannerLoading(false);
    navigate(`/mcdu/plan/ofp${ofpId ? `/${ofpId}` : ``}`);
  };

  const checkSBworker = () => {
    if (sbworker && sbworker.closed) {
      window.clearInterval(SBloop);
      setPayloadOFP('Simbrief', ofp_id, null);
    }
  };

  const doBuiltInPlanner = () => {
    setPlannerLoading(true);
    plannerTransport
      .dispatchFlightPlan({
        orig: origin[0].icao,
        dest: destinaton[0].icao,
        type: airplane[0].codeIcao,
        system: 'BuiltIn',
        fltnum: flightNumber || null,
        reg: registration || null,
        route: route || null,
      })
      .then((payload) => {
        if (payload) {
          setPayloadOFP('BuiltIn', null, payload);
        }
      })
      .catch((e) => {
        setPlannerLoading(false);
        setPlannerError(e);
      });
  };

  const doSimbriefPlanner = (outputpage: string) => {
    setPlannerLoading(true);
    const timestamp = Math.round(+new Date() / 1000);
    const outputpage_calc = outputpage.replace('http://', '');
    const apiform = document.getElementById('sbapiform') as HTMLFormElement;

    apiform.setAttribute('method', 'get');
    apiform.setAttribute('action', sbworkerurl);
    apiform.setAttribute('target', sbworkerid);

    hiddenField('outputpage', outputpage_calc, apiform);
    hiddenField('timestamp', '' + timestamp, apiform);

    plannerTransport
      .authRedirectUrl(
        {
          orig: origin[0].icao,
          dest: destinaton[0].icao,
          type: airplane[0].codeIcao,
          timestamp,
          outputpage_calc,
        },
        'Simbrief',
      )
      .then((hash: string) => {
        hiddenField('apicode', hash, apiform);
        hiddenField('orig', origin[0].icao, apiform);
        hiddenField('dest', destinaton[0].icao, apiform);
        hiddenField('type', airplane[0].codeIcao, apiform);
        hiddenField('reg', registration, apiform);
        hiddenField('fltnum', flightNumber, apiform);
        hiddenField('airline', airplane[0].airline, apiform);
        hiddenField('units', 'LBS', apiform);
        hiddenField('date', dayjs.utc().add(40, 'minutes').format('DDMMMYY').toUpperCase(), apiform);
        hiddenField('deph', dayjs.utc().add(40, 'minutes').format('HH'), apiform);
        hiddenField('depm', dayjs.utc().add(40, 'minutes').format('mm'), apiform);
        hiddenField('manualrmk', 'Route for https://passcargo.app', apiform);
        if (route) {
          hiddenField('route', route, apiform);
        }

        window.name = sbcallerid;
        sbworker = window.open('about:blank', sbworkerid, sbworkerstyle);

        //TEST FOR POPUP BLOCKERS

        if (sbworker == null || typeof sbworker == 'undefined') {
          alert('Please disable your pop-up blocker to generate a flight plan!');
        } else {
          if (window.focus) {
            sbworker.focus();
          }

          apiform.submit();

          ofp_id = timestamp + '_' + md5(origin[0].icao + destinaton[0].icao + airplane[0].codeIcao);

          SBloop = window.setInterval(checkSBworker, 500);
        }
      });
  };

  const importFromSimbrief = () => {
    setPlannerLoading(true);
    plannerTransport
      .importPrefiled('Simbrief')
      .then((payload: any) => {
        window.localStorage.setItem('PASSCARGO_OFP_TYPE', 'Simbrief');
        window.localStorage.setItem('PASSCARGO_OFP', JSON.stringify(payload));
        window.localStorage.setItem('PASSCARGO_OFP_ORIGIN', payload.origin.icao);
        window.localStorage.setItem('PASSCARGO_OFP_DESTINATION', payload.destination.icao);
        window.localStorage.setItem('PASSCARGO_OFP_PLANE_ID', payload.fid);
        setPlannerLoading(false);
        navigate(`/mcdu/plan/ofp`);
      })
      .catch((e) => {
        console.error(e);
        setPlannerLoading(false);
        setImportError(e);
      });
  };

  // http://www.simbrief.com/api/inputs.list.json

  return (
    <>
      <PageHeader title="New Flight" description="It's time to discover something new!" className="mb-3" />
      <Alert variant="info" className="border-2 d-flex align-items-center">
        <div className="bg-info me-4 icon-item">
          <FontAwesomeIcon icon="info-circle" className="text-white fs-3" />
        </div>
        <p className="mb-0 flex-1">
          Already have a route dispatched by SimBrief? No worries, you can simply import it! Let's try!
          <br />
          <br />
          <Button size="sm" onClick={importFromSimbrief.bind(this)}>
            Import from Simbrief
          </Button>
        </p>
      </Alert>
      {plannerError && (
        <Alert variant="danger" className="border-2">
          We are unable to plan requested route
        </Alert>
      )}
      {importError && (
        <Alert variant="danger" className="border-2">
          We were unable to import flight from Simbrief. Check your Simbrief account ID on Settings page.
        </Alert>
      )}
      <Card>
        <Card.Header>
          <h5>Route Definition</h5>
        </Card.Header>
        <Card.Body>
          <Form id="sbapiform">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="planner.Origin">
                  <FormOriginDestSelect
                    id="origin"
                    label="Origin"
                    labelKey="label"
                    value={origin}
                    onChange={onChange.bind(this, 'origin')}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="planner.Destination">
                  <FormOriginDestSelect
                    id="destinaton"
                    label="Destination"
                    labelKey="label"
                    value={destinaton}
                    onChange={onChange.bind(this, 'destinaton')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="planner.Airplane">
                  <FormAirplanesSelect
                    id="airplane"
                    label="Airplane"
                    labelKey="label"
                    value={airplane}
                    airlinesOnly
                    onChange={onChange.bind(this, 'airplane')}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="planner.Registration">
                  <FloatingLabel label="Registration" className="mb-3">
                    <Form.Control
                      ref={regRef}
                      placeholder=""
                      value={registration}
                      onChange={onChange.bind(this, 'registration')}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="planner.Callsign">
                  <FloatingLabel label="Callsign" className="mb-3">
                    <Form.Control placeholder="" value={callsign} onChange={onChange.bind(this, 'callsign')} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="planner.FlightNumber">
                  <FloatingLabel label="Flight Number" className="mb-3">
                    <Form.Control placeholder="" value={flightNumber} onChange={onChange.bind(this, 'flightNumber')} />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="planner.Route">
                  <Form.Label>Route (optional):</Form.Label>
                  <Form.Control as="textarea" rows={3} value={route} onChange={onChange.bind(this, 'route')} />
                  <Form.Text muted>
                    Alternatively, you can define route here and we will file it to planning system for you
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={6} className="text-muted">
              Dispatch route using:
            </Col>
            <Col>
              <div className="float-end">
                <Button disabled={!requestFilled} onClick={doBuiltInPlanner}>
                  Bult-in Planner
                </Button>{' '}
                <Button
                  disabled={!requestFilled}
                  onClick={doSimbriefPlanner.bind(this, 'https://app.passcargo.app/mcdu/plan/ofp')}
                >
                  Simbrief
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {plannerLoading && <SpinnerLoader label="Building flight plan..." />}
    </>
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { flightData } from 'features/flights';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { accountTransport } from 'transport';
import { formatNumber } from 'utils';

const parseEventDescription = (event: string, item: any, flight: any) => {
  const fuelOnBoard = item.fob ? formatNumber(Math.round(Math.round(item.fob))) : '';
  const grossWeight = formatNumber(Math.round(item.zfw + item.fob));
  const units = item.units || 'lbs';

  if (event === 'Start') {
    return `Started flight ${flight.air + (flight.flightNumber || '')} from ${flight.ori_apt.municipality} (${
      flight.ori_apt.iata
    })${flight.des_apt ? ` to ${flight.des_apt.municipality} (${flight.des_apt.iata})` : ``}`;
  }
  if (event === 'Pushback') {
    return `Pushed back from the gate with ${fuelOnBoard} ${units} of fuel. Aircraft gross weight was ${grossWeight} ${units}.`;
  }
  if (event === 'Takeoff') {
    return `Departed from ${flight.ori_apt.municipality} (${flight.ori_apt.iata}) with ${fuelOnBoard} ${units} of fuel on board and engines thrust ${item.en1}%. Aircraft gross weight was ${grossWeight} ${units}.`;
  }
  if (event === 'Cruise') {
    return `Leveled off at a cruising alititude at ${formatNumber(
      flight.cruiseAlt,
    )} feet with ${fuelOnBoard} ${units} of fuel on board. Aircraft gross weight was ${grossWeight} ${units}.`;
  }
  if (event === 'Landed') {
    const msgs = JSON.parse(item.msg || '{}');

    return `Landed ${
      flight.des_apt ? `in ${flight.des_apt.municipality} (${flight.des_apt.iata})` : `in airport`
    }. Touched down at ${msgs.ground_velocity} knots and ${
      msgs.vertical_speed
    } ft/min with ${fuelOnBoard} ${units} of fuel on board. G Force was ${parseFloat(
      msgs.g_force.toFixed(2),
    )}. Aircraft gross weight was ${grossWeight} ${units}.`;
  }
  if (event === 'Completed') {
    return `Flight has been completed`;
  }
  return item.message;
};

export const Timeline = ({ flightId }: { flightId: string }) => {
  const [timelines, setTimelines] = useState([]);
  const flight = useSelector(flightData);

  const doRefresh = () => {
    accountTransport.getLoggedFlightTmelines(flightId).then((payload: any) => {
      setTimelines(payload);
    });
  };

  useEffect(() => {
    doRefresh();

    let refresh = null;

    if (flight?.sts !== 'Completed') {
      refresh = setInterval(() => {
        doRefresh();
      }, 15000);
    }

    return () => {
      clearInterval(refresh);
    };
  }, []);

  return (
    <div className="timeline-vertical">
      {timelines.map((item, index) => {
        const { ts, te } = item;
        return (
          <div
            key={index}
            className={classNames('timeline-item', {
              'timeline-item-start': index % 2 === 0,
              'timeline-item-end': index % 2 !== 0,
            })}
          >
            <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
              <FontAwesomeIcon icon={'check-circle'} className="fs-1" />
            </div>
            <Row className={` ${index % 2 === 0 ? 'timeline-item-start' : 'timeline-item-end'}`}>
              <Col lg={6} className="timeline-item-time">
                <div>
                  <p className="fs--1 mb-0 fw-semi-bold"> {dayjs.utc(ts).local().format('HH:mm')}</p>
                  <p className="fs--2 text-600">{dayjs.utc(ts).local().format('DD MMM YY')}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="timeline-item-content">
                  <div className="timeline-item-card">
                    {/* <h6 className="mb-2">{parseEventTitle(event)}</h6> */}
                    <p className="fs--1 mb-0">{parseEventDescription(te, item, flight)}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

import React from 'react';
import { Route, Routes } from 'react-router';
import { Logbook } from './Logbook';
import { NewFlight } from './NewFlight';
import { Timetable } from './Timetable';
import { OFP } from './OFP';

export const MCDU = () => (
  <Routes>
    <Route path="/plan" element={<NewFlight />} />
    <Route path="/plan/ofp" element={<OFP />} />
    <Route path="/plan/ofp/:ofp_id" element={<OFP />} />
    <Route path="/timetable/*" element={<Timetable />} />
    <Route path="/logbook/*" element={<Logbook />} />
  </Routes>
);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageHeader } from 'components/common/PageHeader';
import { hangarAirplanes } from 'features/hangar';
import React from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import planeImgStub from 'assets/img/plane-stub.png';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { Details } from './Details';
import { HangarAdd } from './add/HangarAdd';

export const Tiles = () => {
  const navigate = useNavigate();
  const airplanes = useSelector(hangarAirplanes);
  const [searchParams] = useSearchParams();

  const toggleView = (plane) => {
    navigate(`view/${plane.id}`, { preventScrollReset: false });
  };

  const actionButtons = (
    <div style={{ marginTop: '15px' }}>
      <Button variant="primary" onClick={() => navigate('/hangar/airplanes/add')}>
        <FontAwesomeIcon icon="plus" /> Add
      </Button>
    </div>
  );

  return (
    <>
      <PageHeader
        title="My Airplanes"
        description="You can manage your fleet in hangar"
        className="mb-3"
        buttons={actionButtons}
      />

      {searchParams.has('added') && (
        <Alert variant="success" className="border-2">
          Airplane has been added to the Hangar
        </Alert>
      )}

      {airplanes.length === 0 && (
        <Alert variant="info" className="border-2 d-flex align-items-center mb-0">
          <div className="bg-info me-3 icon-item">
            <FontAwesomeIcon icon="info-circle" className="text-white fs-3" />
          </div>
          <p className="mb-0 flex-1">There is no airplanes in your hangar</p>
        </Alert>
      )}

      <Routes>
        <Route path="view/:id" element={<Details />} />
        <Route path="add" element={<HangarAdd />} />
      </Routes>

      <Row className="g-4">
        {airplanes.map((plane) => (
          <Col sm={6} md={4} key={`plane-${plane.id}`}>
            <Card style={{ cursor: 'pointer' }} onClick={toggleView.bind(this, plane)}>
              <Card.Img src={planeImgStub} variant="top" />
              <Card.Body>
                <Card.Title as="div" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <span title={plane.name}>{plane.name}</span>
                </Card.Title>
                <Row>
                  <Col xs={2} sm={5}>
                    Code:
                  </Col>
                  <Col xs={10} sm={7}>
                    {plane.codeIcao} / {plane.codeIata}
                  </Col>
                  <Col xs={2} sm={5}>
                    Airline:
                  </Col>
                  <Col xs={10} sm={7}>
                    {plane.airline}
                  </Col>
                  <Col xs={2} sm={5}>
                    Reg #:
                  </Col>
                  <Col xs={10} sm={7}>
                    {plane.aircraftReg || <>&mdash;</>}
                  </Col>
                  <Col xs={2} sm={5}>
                    Location:
                  </Col>
                  <Col xs={10} sm={7}>
                    {plane.location || <>&mdash;</>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

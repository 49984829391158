import { configureStore } from '@reduxjs/toolkit';
import accountReducer from 'features/account';
import flightsReducer from 'features/flights';
import hangarReducer from 'features/hangar';
import trackReducer from 'features/track';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    flights: flightsReducer,
    hangar: hangarReducer,
    track: trackReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

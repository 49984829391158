import { DataBlock } from 'components/common/DataBlock';
import { flightData } from 'features/flights';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils';

export const InfoBrief = () => {
  const flight = useSelector(flightData);

  return (
    <Row>
      <Col md={6}>
        <DataBlock compact label="Aircraft" value={flight.aircraftName || flight.code} />
        <DataBlock compact nomargin label="Flight Number" value={flight.air + (flight.flightNumber || '')} />
      </Col>
      <Col md={6}>
        <DataBlock compact label="CI" value={flight.costIndex || 12} />
        <DataBlock
          compact
          nomargin
          label="Cruise"
          value={`${flight.cruiseAlt ? `${formatNumber(flight.cruiseAlt)} ft` : 'Not Set'}`}
        />
      </Col>
    </Row>
  );
};

import { createSlice } from '@reduxjs/toolkit';

type StateProps = {
  track: any;
};

export const hangarSlice = createSlice({
  name: 'track',
  initialState: {
    flights: [],
  },
  reducers: {
    loadTrackMapData: (state, action) => {
      state.flights = action.payload || [];
    },
  },
});

export const { loadTrackMapData } = hangarSlice.actions;

export const trackFlightsData = (state: StateProps) => state.track.flights;

export default hangarSlice.reducer;

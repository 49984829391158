import { createContext } from 'react';
import { ConfigProps, settings } from 'config';

const AppContext = createContext({config: settings} as ConfigProps);

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const ChatContext = createContext([]);

export const KanbanContext = createContext({
    KanbanColumns: [],
    kanbanTasks: []
});

export default AppContext;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Error400 } from './Error400';
import { Error404 } from './Error404';
import { Error403 } from './Error403';
import { Error500 } from './Error500';

export const Errors = () => {
    const { code } = useParams();
    if (code === "400") {
        return <Error400 />
    }
    if (code == "404") {
        return <Error404 />
    }
    if (code == "403") {
        return <Error403 />
    }
    return <Error500 />;
}
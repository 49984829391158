import { DataBlock } from 'components/common/DataBlock';
import Divider from 'components/common/Divider';
import dayjs from 'dayjs';
import { flightData } from 'features/flights';
import { trackFlightsData } from 'features/track';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { formatNumber } from 'utils';
import { findById } from './utils';

export const ScheduleBrief = () => {
  const flight = useSelector(flightData);
  const activeFlights = useSelector(trackFlightsData) as any;
  const { flightId } = useParams();
  const [traveled, setTraveled] = useState(0);
  const [airTime, setAirtime] = useState(0);

  useEffect(() => {
    if (!flight) {
      return;
    }

    if (flight.sts !== 'Completed') {
      const f: any = findById(activeFlights, flightId);

      if (f) {
        setTraveled(f.trdis);

        if (flight.ta) {
          const t = dayjs(flight.ta);

          setAirtime(dayjs().utc().diff(t, 'seconds'));
        }
      } else {
        setTraveled(0);
        setAirtime(0);
      }
    } else {
      setTraveled(flight.distance);
      setAirtime(flight.duration);
    }
  }, [activeFlights]);

  let timeRemaining = flight.estEnroute - airTime;
  if (timeRemaining < 0) {
    timeRemaining = 0;
  }

  let distRemaining = flight.estDistance - traveled;
  if (distRemaining < 0) {
    distRemaining = 0;
  }

  return (
    <>
      <Row>
        <Col md={6}>
          <h6>Out Time</h6>
          <div style={{ fontSize: '80%' }}>
            Scheduled: {flight.schedOut ? dayjs(flight.schedOut * 1000).format('HH:mm') : 'N/A'}
          </div>
          <div style={{ fontSize: '80%' }}>Actual: {flight.ts && dayjs(flight.ts).format('HH:mm')}</div>
          <h6 className="mt-4">On Time</h6>
          <div style={{ fontSize: '80%' }}>
            Scheduled: {flight.schedOn ? dayjs(flight.schedOn * 1000).format('HH:mm') : 'N/A'}
          </div>
          <div style={{ fontSize: '80%' }}>Actual: {flight.td && dayjs(flight.td).format('HH:mm')}</div>
        </Col>
        <Col md={6}>
          <h6>Off Time</h6>
          <div style={{ fontSize: '80%' }}>
            Scheduled: {flight.schedOff ? dayjs(flight.schedOff * 1000).format('HH:mm') : 'N/A'}
          </div>
          <div style={{ fontSize: '80%' }}>Actual: {flight.ta && dayjs(flight.ta).format('HH:mm')}</div>
          <h6 className="mt-4">In Time</h6>
          <div style={{ fontSize: '80%' }}>
            Scheduled: {flight.schedIn ? dayjs(flight.schedIn * 1000).format('HH:mm') : 'N/A'}
          </div>
          <div style={{ fontSize: '80%' }}>Actual: {flight.tf && dayjs(flight.tf).format('HH:mm')}</div>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col md={6}>
          <DataBlock
            compact
            nomargin={flight.sts === 'Completed' || isNaN(timeRemaining)}
            label="Time Flown"
            value={`${dayjs.duration(airTime, 'seconds').format('HH:mm')}`}
          />
          {flight.sts !== 'Completed' && !isNaN(timeRemaining) && (
            <DataBlock
              compact
              nomargin
              label="Time Remaining"
              value={`${dayjs.duration(timeRemaining, 'seconds').format('HH:mm')}`}
            />
          )}
        </Col>
        <Col md={6}>
          <DataBlock
            compact
            nomargin={flight.sts === 'Completed' || !flight.estDistance}
            label="Distance Flown"
            value={`${formatNumber(Math.round(traveled))} nm`}
          />
          {flight.sts !== 'Completed' && flight.estDistance > 0 && (
            <DataBlock
              compact
              nomargin
              label="Distance Remaining"
              value={`${formatNumber(Math.round(distRemaining))} nm`}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

import * as base from './base';

export type FetchFlightPlanRequest = {
  orig: string;
  dest: string;
  altn?: string;
  type: string;
  system: string;
  maxAltitude?: string;
  route?: string;
  airline?: string;
  reg?: string;
  fltnum?: string;
  units?: string;
  date?: string;
  deph?: number;
  depm?: number;
};

export type DispatchRedirectLinkRequest = {
  orig: string;
  dest: string;
  type: string;
  timestamp: number;
  outputpage_calc: string;
};

export type FlightStateRequest = {
  traveled_distance: number;
  block_end_time: Date;
  block_duration: number;
  phase: string;
  timeline: any;
};

export const loadAirports = (query: string) => base.get(`${base.FLY_API_ROOT_URL}/share/airport/find?name=${query}`);

export const dispatchFlightPlan = (params: FetchFlightPlanRequest) => {
  const getParams = [];
  Object.keys(params).forEach((k) => {
    if (k) {
      getParams.push(`${k}=${encodeURI(params[k])}`);
    }
  });
  return base.get(`${base.FLY_API_ROOT_URL}/planner/dispatch?${getParams.join('&')}`);
};

export const fetchFlightPlan = (ofpId: string, system: string) =>
  base.get(`${base.FLY_API_ROOT_URL}/planner/fetch?system=${system}&id=${ofpId}`);

export const authRedirectUrl = (req: DispatchRedirectLinkRequest, system: string) =>
  base.post(`${base.FLY_API_ROOT_URL}/planner/redirectUrl?system=${system}`, req);

export const startFlight = (payload: any) => base.post(`${base.FLY_API_ROOT_URL}/planner/pending`, payload);

export const cancelFlight = (id: string) => base.del(`${base.FLY_API_ROOT_URL}/track/cancel/${id}`);

export const completeFlight = (id: string, req: FlightStateRequest) =>
  base.post(`${base.FLY_API_ROOT_URL}/track/stop/${id}`, req);

export const getFlightStatus = (id: string) => base.get(`${base.FLY_API_ROOT_URL}/track/status/${id}`);

export const importPrefiled = (system: string) => base.get(`${base.FLY_API_ROOT_URL}/planner/import?system=${system}`);

export const lookupAirports = async (idents: string[]) => {
  const data = {};

  for (let i = 0; i < idents.length; i++) {
    const ident = idents[i];
    const apt = await base.get(`${base.FLY_API_ROOT_URL}/share/airport/${ident}`).catch((e) => {});
    if (apt) {
      data[ident] = apt;
    }
  }

  return data;
};

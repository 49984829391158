import React from 'react';
import classNames from 'classnames';

type BackgroundProps = {
    image?: string;
    overlay?: boolean | string;
    position?: any;
    video?: [];
    className?: string;
    style?: any;
};

export const Background = ({
    image,
    overlay,
    position,
    video,
    className,
    style
}: BackgroundProps) => {
    const bgStyle = { backgroundImage: `url(${image})`, ...style };
    if (typeof position === 'string') {
        bgStyle.backgroundPosition = position;
    } else if (typeof position === 'object') {
        position.x && (bgStyle.backgroundPositionX = position.x);
        position.y && (bgStyle.backgroundPositionY = position.y);
    }

    return (
        <div
            className={classNames(
                'bg-holder',
                {
                    overlay: overlay,
                    [`overlay-${overlay}`]: typeof overlay === 'string'
                },
                className
            )}
            style={bgStyle}
        >
            {video && (
                <video className="bg-video" autoPlay loop muted playsInline>
                    {video.map((src: string, index) => (
                        <source
                            key={index}
                            src={src}
                            type={`video/${src.split('.').pop()}`}
                        />
                    ))}
                </video>
            )}
        </div>
    );
};

import pkg from '../package.json';

export const version = pkg.version;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';

export type ConfigProps = {
    config: {
        isFluid: boolean;
        isRTL: boolean;
        isDark: boolean;
        navbarPosition: string;
        showBurgerMenu: boolean;
        showSettingPanel: boolean;
        navbarCollapsed: boolean;
        currency: string;
        isNavbarVerticalCollapsed: boolean;
        navbarStyle: string;
    },
    setConfig: (k: string, v: any) => void;
    configDispatch: React.Dispatch<any>;
};

export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    navbarPosition: 'vertical',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent'
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };

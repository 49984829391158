export type RouteCategoryProps = {
  label: string;
  labelDisable?: boolean;
  children: RouteProps[];
};

export type RouteProps = {
  exact?: boolean;
  active?: boolean;
  name: string;
  to?: string;
  icon?: string | string[];
  children?: RouteProps[];
  label?: string;
  badge?: BadgeProps;
  showFn?: (e: any) => boolean;
};

export type BadgeProps = {
  type: string;
  text: string;
};

export const dashboardRoutes: RouteCategoryProps = {
  label: 'Dashboard',
  children: [
    {
      name: 'Dashboard',
      icon: 'globe',
      to: '/',
    },
    {
      name: 'Active Flight',
      icon: 'clock',
      to: '/flight/:flightId',
    },
  ],
};

export const mcduRoutes: RouteCategoryProps = {
  label: 'MCDU',
  children: [
    {
      name: 'New Flight',
      icon: 'file',
      to: '/mcdu/plan',
    },
    // {
    //   name: 'Timetable',
    //   icon: 'th',
    //   to: '/mcdu/timetable',
    // },
    {
      name: 'Logbook',
      icon: 'book',
      to: '/mcdu/logbook',
    },
  ],
};

export const hangarRoutes: RouteCategoryProps = {
  label: 'Hangar',
  children: [
    {
      name: 'Airplanes',
      icon: 'paper-plane',
      to: '/hangar/airplanes',
    },
  ],
};

export const settingsRoutes: RouteCategoryProps = {
  label: 'Settings',
  children: [
    {
      name: 'General',
      icon: 'wrench',
      to: '/settings/general',
    },
    {
      name: 'Streaming',
      icon: 'circle',
      to: '/settings/streaming',
    },
  ],
};

export const downloadsRoutes: RouteCategoryProps = {
  label: 'Addons',
  children: [
    {
      name: 'Desktop EFB',
      icon: 'cloud-download-alt',
      to: '/addons/desktop-efb',
    },
  ],
};

export const supportRoutes: RouteCategoryProps = {
  label: 'Support',
  children: [
    {
      name: 'Getting Started',
      icon: 'rocket',
      to: '/support/start',
    },
    {
      name: 'FAQ',
      icon: 'circle-question',
      to: '/support/faq',
    },
  ],
};

export default [dashboardRoutes, mcduRoutes, hangarRoutes, settingsRoutes, downloadsRoutes, supportRoutes];

import { createSlice } from '@reduxjs/toolkit';

type StateProps = {
  flights: any;
  flight: any;
};

type FlightStateProps = {};

export const flightsSlice = createSlice({
  name: 'flights',
  initialState: {
    history: null,
    flight: null,
  },
  reducers: {
    loadFlights: (state, action) => {
      state.history = action.payload;
    },
    loadFlightById: (state, action) => {
      state.flight = action.payload;
    },
  },
});

export const { loadFlights, loadFlightById } = flightsSlice.actions;

export const historyFlights = (state: StateProps) => state.flights.history;
export const flightData = (state: StateProps) => state.flights.flight;

export default flightsSlice.reducer;

import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { GettingsStarted } from './GettingStarted';
import { Faq } from './Faq';

export const Support = () => (
  <Routes>
    <Route path="/start" element={<GettingsStarted />} />
    <Route path="/faq" element={<Faq />} />
  </Routes>
);

import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { General } from './General';
import { StreamingComponent } from './Streaming';
import { useDispatch, useSelector } from 'react-redux';
import { settings as accountSettings, loadSettings } from 'features/account';
import { accountTransport } from 'transport';
import { Loading } from 'components/common/Loading';

const LoadComponent = ({ element }) => {
  const settings = useSelector(accountSettings);

  return settings.streaming === null ? <Loading /> : element;
};

export const Settings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    accountTransport.loadSettings().then((payload) => dispatch(loadSettings(payload)));
  }, []);

  return (
    <Routes>
      <Route path="/general" element={<LoadComponent element={<General />} />} />
      <Route path="/streaming" element={<LoadComponent element={<StreamingComponent />} />} />
    </Routes>
  );
};

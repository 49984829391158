import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
Chart.register(Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

export const ChartAltProfile = ({ source }) => {
  if (!source) {
    return null;
  }

  const vals = [];
  const labels = [];

  for (let index = source.length - 1; index >= 0; index--) {
    if (index % 40 === 0) {
      const row = source[index];

      labels.push(row.trdis);
      vals.push(Math.round(row.alt));
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Altitude',
        borderColor: getColor('primary'),
        borderWidth: 1,
        fill: false,
        data: vals,
        tension: 0.3,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: chartJsDefaultTooltip(),
    },
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1),
        },
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1),
          drawBorder: true,
        },
      },
    },
  };

  return <Line data={data} options={options} height={1000} width={1618} />;
};

export const ChartSpeedProfile = ({ source }) => {
  if (!source) {
    return null;
  }

  const vals = [];
  const labels = [];

  for (let index = source.length - 1; index >= 0; index--) {
    if (index % 40 === 0) {
      const row = source[index];

      labels.push(row.trdis);
      vals.push(row.ground_velocity);
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Airspeed (Ground Velocity)',
        borderColor: getColor('primary'),
        borderWidth: 1,
        fill: false,
        data: vals,
        tension: 0.3,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: chartJsDefaultTooltip(),
    },
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1),
        },
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1),
          drawBorder: true,
        },
      },
    },
  };

  return <Line data={data} options={options} height={1000} width={1618} />;
};

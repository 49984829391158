import { Feature } from 'geojson';
import { GeoJSON } from 'react-leaflet';
import { LatLngExpression, circleMarker } from 'leaflet';
import React from 'react';

export const Features = React.forwardRef(({ data }: any, ref: any) => (
  <GeoJSON
    ref={ref}
    data={data}
    pointToLayer={(feature: Feature, latlng: LatLngExpression) => {
      const label = String(feature.properties.ident);
      return circleMarker(latlng, { radius: 1 }).bindTooltip(label, { permanent: true, opacity: 0.7 }).openTooltip();
    }}
  />
));

import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import is from 'is_js';

import { Welcome } from 'components/welcome/Welcome';
import { Login } from 'components/authentication/Login';
import { Logout } from 'components/authentication/Logout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import AppContext from 'context/Context';

import { Goto } from 'components/common/Goto';
import { Errors } from 'components/errors/Index';
import { ErrorLayout } from 'layouts/ErrorLayout';
import { MainLayout } from './MainLayout';
import { Addons } from 'components/addons/Addons';
import { Settings } from 'components/settings/Settings';
import { MCDU } from 'components/mcdu/MCDU';
import { Support } from 'components/support/Support';
import { Hangar } from 'components/hangar/Hangar';
import { FlightOverview } from 'components/flights/Overview';
import { CookieConsent } from 'components/cookies/CookieConsent';
import AuthSimpleLayout from './AuthSimpleLayout';

const Layout = ({ logged }: { logged: boolean }) => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const cookieAccepted = (window.localStorage.getItem('C_ACPT') || 'false') === 'true';

  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={logged ? <MainLayout /> : <Navigate to="/login" replace />}>
          <Route path="/" element={<Welcome />} />
          <Route path="/flight/:flightId" element={<FlightOverview />} />
          <Route path="/addons/*" element={<Addons />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/support/*" element={<Support />} />
          <Route path="/mcdu/*" element={<MCDU />} />
          <Route path="/hangar/*" element={<Hangar />} />
        </Route>
        <Route element={<AuthSimpleLayout />}>
          <Route path="/login" element={logged ? <Navigate to="/" replace /> : <Login />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/goto/*" element={<Goto />} />
        <Route element={<ErrorLayout />}>
          <Route path="/errors/:code" element={<Errors />} />
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <SettingsToggle />
      <SettingsPanel />
      <ToastContainer closeButton={CloseButton} icon={false} position={toast.POSITION.BOTTOM_LEFT} />
      {!cookieAccepted && <CookieConsent />}
    </>
  );
};

export default Layout;

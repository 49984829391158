import React, { useCallback, useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { hangarTransport } from 'transport';

type FormAirlinesSelectProps = {
  value: any;
  id?: string;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange: (v: any) => void;
};

type Item = {
  id: string;
  codeIata: string;
  codeIcao: string;
  name: string;
};

const findAirlines = (query: string) => {
  return hangarTransport.loadAirlines(query).then((payload: Item[]) => {
    const opts = [];

    payload.forEach((o) => {
      if (o.codeIcao) {
        opts.push({ value: o.codeIcao, ...o });
      }
    });

    return opts;
  });
};

export const FormAirlinesSelect = ({
  label,
  value,
  onChange,
  id,
  readOnly = false,
  disabled = false,
}: FormAirlinesSelectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Item[]>([]);
  const [selected, setSelected] = useState([]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (value && value.length > 2) {
      findAirlines(value).then((opts) => {
        setOptions(opts);
        const scltd = opts.filter((o) => o.value === value);

        setSelected(scltd ? scltd : value);
        setKey(Math.random());
      });
    }
  }, [value]);

  const handleSearch = (query: string) => {
    setIsLoading(true);

    findAirlines(query).then((opts) => {
      setOptions(opts);
      setIsLoading(false);
    });
  };

  return (
    <>
      {!readOnly && (
        <AsyncTypeahead
          key={key}
          id={id}
          labelKey={(o: any) => {
            if (o[0]) {
              return `${o[0].codeIcao} - ${o[0].name}`;
            }
            if (o.codeIcao) {
              return `${o.codeIcao} - ${o.name}`;
            }
            return '';
          }}
          filterBy={() => true}
          isLoading={isLoading}
          minLength={3}
          onSearch={handleSearch}
          options={options}
          defaultSelected={selected}
          disabled={disabled}
          onChange={(o: any) => {
            o[0] && onChange(o[0].value);
            o.length === 0 && onChange(undefined);
          }}
          renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => {
            return (
              <FloatingLabel controlId={id} label={label}>
                <Form.Control
                  value={value as string}
                  {...inputProps}
                  ref={(node) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                />
              </FloatingLabel>
            );
          }}
        />
      )}
      {selected[0] && readOnly && (
        <FloatingLabel label={label} className="mb-3">
          <Form.Control plaintext readOnly value={`${selected[0].codeIcao} - ${selected[0].name}`} />
        </FloatingLabel>
      )}
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit';

type StateProps = {
  account: AccountStateProps;
};

type AccountStateProps = {
  settings: SettingsStateProps;
  summary: {
    totalFlights: number;
    traveledDistance: number;
    activeFlight: null | FlightStateProps;
    recentFlights: null | Array<FlightStateProps>;
    directions: null | Array<DirectionLatLon>;
  };
  account: AccountInfoProps;
  stats: any;
};

export type DirectionLatLon = {
  origin: string;
  originLat: number;
  originLon: number;
  destination: string;
  destinationLat: number;
  destinationLon: number;
};

type AccountInfoProps = {
  name: string;
  locale: string;
  avatar?: string;
  pilotId: string;
};

type FlightStateProps = {
  id: string;
  origin: string;
  destination: string;
  createdAt: string;
};

type SettingsStateProps = {
  general: GeneralSettingsStateProps;
  streaming: StreamingSettingsStateProps;
};

type GeneralSettingsStateProps = {
  pilotId: string;
  simbrief?: string;
};

type StreamingSettingsStateProps = {
  orange: boolean;
  announciators: boolean;
  hdg: boolean;
  spd: boolean;
  alt: boolean;
  vs: boolean;
  orig: boolean;
  dest: boolean;
  dist: boolean;
  trav: boolean;
  airc: boolean;
  airl: boolean;
  reg: boolean;
  call: boolean;
  phs: boolean;
  botText: string;
};

const defaultStreamingState: StreamingSettingsStateProps = {
  orange: false,
  announciators: false,
  hdg: false,
  spd: false,
  alt: false,
  vs: false,
  orig: false,
  dest: false,
  dist: false,
  trav: false,
  airc: false,
  airl: false,
  reg: false,
  call: false,
  phs: false,
  botText: '',
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    settings: {
      general: {
        pilotId: '',
      },
      streaming: null,
    },
    summary: {
      totalFlights: 0,
      traveledDistance: 0,
      activeFlight: null,
      recentFlights: null,
      directions: null,
    },
    account: {},
    stats: null,
  },
  reducers: {
    loadSettings: (state, action) => {
      const { pilotId, settings } = action.payload;

      let general = {
        pilotId: pilotId,
      };

      let streaming = defaultStreamingState;

      settings?.forEach((item: any) => {
        if (item.section === 'general') {
          const stgs = JSON.parse(item.settings);

          general = {
            ...stgs,
            ...general,
          } as GeneralSettingsStateProps;
        }
        if (item.section === 'streaming') {
          const stgs = JSON.parse(item.settings);

          streaming = {
            ...streaming,
            ...stgs,
          };
        }
      });

      state.settings = {
        general,
        streaming,
      };
    },
    loadSummary: (state, action) => {
      const { total_flights, traveled_distance, active_flight, recent_flights, directions } = action.payload;
      state.summary = {
        totalFlights: total_flights || 0,
        traveledDistance: traveled_distance || 0,
        activeFlight: active_flight,
        recentFlights: recent_flights,
        directions,
      };
    },
    loadAccountInfo: (state, action) => {
      state.account = action.payload;
    },
    loadStatistic: (state, action) => {
      state.stats = action.payload;
    },
  },
});

export const { loadSettings, loadSummary, loadAccountInfo, loadStatistic } = accountSlice.actions;

export const settings = (state: StateProps) => state.account.settings;
export const summary = (state: StateProps) => state.account.summary;
export const account = (state: StateProps) => state.account.account;
export const stats = (state: StateProps) => state.account.stats;

export default accountSlice.reducer;

import logo from 'assets/img/passcargo-logo-64.png';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Logo.scss';

type Props = {
  id?: string;
  at?: any;
  width?: number;
  className?: string;
  textClass?: string;
};

export const Logo = ({ at = 'auth', width = 300, className, ...rest }: Props) => {
  const {
    config: { isDark },
  } = useContext(AppContext);

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' },
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth',
          },
          className,
        )}
      >
        <img className="me-2" src={logo} alt="Logo" width={width} />

        <span className={classNames('passcargo-logo', { 'passcargo-logo-dark': isDark })}>PassCargo</span>
      </div>
    </Link>
  );
};

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const CookieConsent = () => {
  const [bannerOpen, setBannerOpen] = React.useState(true);

  const closeBanner = () => {
    setBannerOpen(false);
  };

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Cookie Consent</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          We use cookies to recognize your repeated visits and preferences, as well as to measure the effectiveness of
          our documentation and whether users find what they're searching for. With your consent, you're helping us to
          make our documentation better.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            document.location = 'https://flysim.io';
          }}
        >
          Reject All
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            window.localStorage.setItem('C_ACPT', 'true');
            document.location.reload();
          }}
        >
          Allow All
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { stats } from 'features/account';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { StatPieChart } from './StatPieChart';

export const StatByAirline = () => {
  const stat = useSelector(stats);

  return (
    stat && (
      <Card>
        <Card.Header className={'bg-light'}>
          <h5>Airlines</h5>
        </Card.Header>
        <Card.Body>
          <StatPieChart source={stat.byAirlines} />
        </Card.Body>
      </Card>
    )
  );
};

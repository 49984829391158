import React from 'react';
import classNames from 'classnames';

type FlexProps = {
    children?: any;
    justifyContent?: string;
    inline?: boolean;
    alignItems?: string;
    alignContent?: string;
    wrap?: string;
    className?: string;
    tag?: string;
    breakpoint?: string;
    direction?: string;
};

const Flex = ({
    justifyContent,
    alignItems,
    alignContent,
    inline,
    wrap,
    className,
    tag = 'div',
    children,
    breakpoint,
    direction,
    ...rest
}: FlexProps) => {
    const Cmp = tag as any;
    return (
        <Cmp
            className={classNames(
                {
                    [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
                    [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]:
                        inline,
                    [`flex-${direction}`]: direction,
                    [`justify-content-${justifyContent}`]: justifyContent,
                    [`align-items-${alignItems}`]: alignItems,
                    [`align-content-${alignContent}`]: alignContent,
                    [`flex-${wrap}`]: wrap
                },
                className
            )}
            {...rest}
        >
            {children}
        </Cmp>
    );
};

export default Flex;

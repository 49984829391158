import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor } from 'helpers/utils';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(ArcElement, Tooltip, Legend);

const rgbs = [
  '#a1c0dd',
  '#5f94c6',
  '#427fba',
  '#3e77ae',
  '#2a5278',
  '#203d5a',
  '#132536',
  '#284e72',
  '#a1c0dd',
  '#0f1c2a',
];

export const StatPieChart = ({ source }) => {
  if (!source) {
    return null;
  }
  const loData = [];
  const loBg = [];
  const loLabels = [];

  source.forEach((b, idx) => {
    loData.push(b.cnt);
    loLabels.push(b.name);
    loBg.push(rgbs[idx]);
  });

  const data = {
    datasets: [
      {
        data: loData,
        backgroundColor: loBg,
        borderWidth: 1,
        borderColor: getColor('gray-100'),
      },
    ],
    labels: loLabels,
  };

  const options = {
    plugins: {
      tooltip: chartJsDefaultTooltip(),
      legend: {},
    },
    maintainAspectRatio: false,
  };

  return <Pie data={data} options={options} height={300} width={300} />;
};

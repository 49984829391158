import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { accountTransport, getLoggedInfo, hangarTransport, setSessionData } from 'transport';
import { LoggedState } from 'context/types';
import { useDispatch } from 'react-redux';
import { loadAccountInfo, loadSummary } from 'features/account';
import { loadAirframes, loadAirplanes } from 'features/hangar';
import { useSocket } from 'transport/socket';

const App = () => {
  const [loggedState, setLoggedState] = useState(LoggedState.IN);
  const dispatch = useDispatch();
  useSocket();

  useEffect(() => {
    if (loggedState !== LoggedState.IN) {
      return;
    }

    getLoggedInfo()
      .then((c) => {
        setSessionData(c);
        setLoggedState(LoggedState.OK);
        dispatch(loadAccountInfo(c));
      })
      .catch(() => {
        console.error('User is not logged');
        setLoggedState(LoggedState.NO);
      });
  }, [loggedState]);

  useEffect(() => {
    if (loggedState !== LoggedState.OK) {
      return;
    }
    hangarTransport.loadAirframes().then((payload) => {
      dispatch(loadAirframes(payload));
    });
    hangarTransport.loadFleet().then((payload) => {
      dispatch(loadAirplanes(payload));
    });

    accountTransport.loadSummary().then((payload) => dispatch(loadSummary(payload)));
  }, [loggedState]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {loggedState !== LoggedState.IN && <Layout logged={loggedState === LoggedState.OK} />}
    </Router>
  );
};

export default App;

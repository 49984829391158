import React, { useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { plannerTransport } from 'transport';
import { AirportCard } from '../AirportCard';

type FormOriginDestSelectProps = {
  value: any;
  id?: string;
  label: string;
  labelKey?: string;
  onChange: (v: any) => void;
};

type Item = {
  icao: string;
  iata: string;
  name: string;
  country: string;
  municipality: string;
};

export const FormOriginDestSelect = ({ label, value, onChange, id, labelKey }: FormOriginDestSelectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Item[]>([]);

  const handleSearch = (query: string) => {
    setIsLoading(true);

    plannerTransport.loadAirports(query).then((payload: Item[]) => {
      const opts = [];

      payload.map((o) => {
        opts.push({ id: o.icao, label: o.name, ...o });
      });

      setOptions(opts);
      setIsLoading(false);
    });
  };

  return (
    <AsyncTypeahead
      id={id}
      labelKey={labelKey}
      filterBy={() => true}
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      options={options}
      onChange={onChange}
      defaultSelected={value}
      placeholder="Enter airport code or name..."
      renderMenuItemChildren={(item: Item) => <AirportCard item={item} compact />}
      renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => (
        <FloatingLabel controlId={id} label={label}>
          <Form.Control
            value={value as string}
            {...inputProps}
            ref={(node) => {
              inputRef(node);
              referenceElementRef(node);
            }}
          />
        </FloatingLabel>
      )}
    />
  );
};

import { Feature } from 'geojson';
import { LatLngExpression, LatLngTuple } from 'leaflet';

export const plannedRouteOptions = {
  color: '#B49DA0',
  dashArray: '5 5',
  weight: 1,
};

export const trackRouteOptions = { color: 'blue', weight: 1 };

export const directionsRouteOptions = { color: '#8AB4F8', weight: 1 };

export interface Waypoint {
  ident: string;
  alt: number;
  lon: number;
  lat: number;
  name: string;
  type: string;
  via: any;
}

export interface Tracklog {
  alt: number;
  lng: number;
  lat: number;
  heading: string;
  airspeed: string;
  airspeed_true: string;
  ground_velocity: string;
}

export type TrackMapProps = {
  navlog?: Waypoint[];
  tracklog?: Tracklog[];
  center?: LatLngTuple;
  zoom?: number;
  active: any[];
};

export type PlannedRoute = {
  features: Feature[];
  poly: LatLngExpression[];
};

export const usePlannedRoute = (navlog: Waypoint[]): PlannedRoute | null => {
  if (!navlog || navlog?.length === 0) {
    return null;
  }

  const poly = [];
  const features = [];

  navlog.forEach((row) => {
    poly.push([row.lat, row.lon]);
    features.push({
      type: 'Feature',
      properties: {
        ...row,
      },
      geometry: {
        type: 'Point',
        coordinates: [row.lon, row.lat],
      },
    });
  });

  return {
    features,
    poly,
  };
};

export const useTrackRoute = (tracklog: Tracklog[]): LatLngExpression[] | null => {
  if (!tracklog || tracklog.length === 0) {
    return null;
  }

  const poly = [];

  tracklog.forEach((row) => poly.push([row.lat, row.lng]));

  return poly;
};

export const detectZoom = (distance: number): number => {
  let zoom = 5;
  if (distance < 500) {
    zoom = 6;
  }
  if (distance > 3000) {
    zoom = 3;
  }
  if (distance > 4500) {
    zoom = 2;
  }
  if (distance > 6000) {
    zoom = 1;
  }
  return zoom;
};

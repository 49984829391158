import { PageHeader } from 'components/common/PageHeader';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { settings as accountSettings, loadSettings } from 'features/account';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './General.module.scss';
import { accountTransport } from 'transport';

export const General = () => {
  const dispatch = useDispatch();
  const [displayPilotId, setDisplayPilotId] = useState(false);
  const settings = useSelector(accountSettings);
  const [values, setValues] = useState(settings.general);
  const { pilotId, simbrief } = values;
  const masked = (
    <>
      <span className={styles.secured}>********</span>
      &nbsp;-&nbsp;
      <span className={styles.secured}>****</span>
      &nbsp;-&nbsp;
      <span className={styles.secured}>****</span>
      &nbsp;-&nbsp;
      <span className={styles.secured}>************</span>
    </>
  );

  const onChange = (field: string, e: any) => {
    let general = { ...values };
    general[field] = e.target.value;
    setValues(general);
    accountTransport.saveSettings({ ...settings, general }).then((payload) => {
      dispatch(loadSettings(payload));
    });
  };

  return (
    <>
      <PageHeader title="General Settings" description="Change basic account information" className="mb-3" />

      <Card>
        <Card.Header>
          <h5>System Information</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Pilot ID
              </Form.Label>
              <Col sm="7" className="secured">
                {displayPilotId && <Form.Control plaintext readOnly defaultValue={pilotId} />}
                {!displayPilotId && <div>{masked}</div>}
                <Form.Text muted>Keep this data secured</Form.Text>
              </Col>
              <Col className={styles.actions}>
                {pilotId && (
                  <Button
                    variant={displayPilotId ? 'primary' : 'outline-primary'}
                    onClick={() => setDisplayPilotId(!displayPilotId)}
                  >
                    <FontAwesomeIcon icon="eye" />
                  </Button>
                )}
                {pilotId && (
                  <Button variant="outline-primary" onClick={() => navigator.clipboard.writeText(`${pilotId}`)}>
                    <FontAwesomeIcon icon="copy" />
                  </Button>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Simbrief ID
              </Form.Label>
              <Col sm="7" className="secured">
                <Form.Control value={simbrief} onChange={onChange.bind(this, 'simbrief')} />
              </Col>
              <Col className={styles.actions}></Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

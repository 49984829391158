import { Waypoint } from 'components/livemap/Route';
import React from 'react';
import { Table } from 'react-bootstrap';

export const Waypoints = ({ waypoints }: { waypoints: Waypoint[] }) => (
  <Table striped size="sm">
    <thead>
      <tr>
        <th>ID</th>
        <th>Type</th>
        <th>Via</th>
        <th>Altitude (ft/m)</th>
        <th>Position (lat/lon)</th>
        <th>Name</th>
      </tr>
    </thead>
    <tbody>
      {Object.values(waypoints).map((row: Waypoint) => (
        <tr key={`wp${row.ident}${row.type}`}>
          <td>{row.ident}</td>
          <td>{row.type}</td>
          <td className="no-wrap">{row.via ? row.via.ident + ' (' + row.via.type + ')' : '-'}</td>
          <td className="no-wrap">
            {' '}
            {row.alt.toLocaleString()} / {parseInt((row.alt / 3.281).toFixed(0), 0).toLocaleString()}
          </td>
          <td className="no-wrap">
            {row.lat.toFixed(5)} / {row.lon.toFixed(5)}
          </td>
          <td>{row.name ? row.name : '-'}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

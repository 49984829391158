import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

export const Error403 = () => (
  <Card className="text-center">
    <Card.Body className="p-5">
      <div className="display-1 text-300 fs-error">403</div>
      <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
        Access is forbidden
      </p>
      <hr />
      <p>
        We are unable to verify your request. Try again later.
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon={faHome} className="me-2" />
        Take me home
      </Link>
    </Card.Body>
  </Card>
);

import LoginForm from 'components/authentication/LoginForm';
import Flex from 'components/common/Flex';

export const Login = () => {
  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Log in using social networks</h5>
      </Flex>
      <LoginForm />
    </>
  );
};

import { useEffect } from 'react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { setToken } from 'transport/base';
import { GetLoginLink } from 'transport/commonDispatcher';

import { oauthCallback, validateDevice } from 'transport/commonDispatcher';

const GoogleRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    oauthCallback('google', window.location.search)
      .then((res: any) => {
        setToken({ token_type: 'Bearer', access_token: res.result });
        window.location.href = '/';
      })
      .catch((err: any) => {
        navigate(`/errors/${err.statusCode}`, { replace: true });
      });
  }, []);

  return null;
};

const FacebookRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    oauthCallback('facebook', window.location.search)
      .then((res: any) => {
        setToken({ token_type: 'Bearer', access_token: res.result });
        window.location.href = '/';
      })
      .catch((err: any) => {
        navigate(`/errors/${err.statusCode}`, { replace: true });
      });
  }, []);

  return null;
};

const DeviceRedirect = () => {
  const navigate = useNavigate();
  const { code } = useParams();

  useEffect(() => {
    if (!code) {
      return;
    }
    validateDevice(code)
      .then((res: any) => {
        navigate(`/settings/devices`, { replace: true });
      })
      .catch((err: any) => {
        if (err.statusCode === 403) {
          GetLoginLink('google', code).then((res: any) => {
            window.location.href = res.url;
          });
          return;
        }
        navigate(`/errors/${err.statusCode}`, { replace: true });
      });
  }, []);

  return null;
};

export const Goto = () => (
  <>
    <Routes>
      <Route path="redirect/google" element={<GoogleRedirect />} />
      <Route path="redirect/facebook" element={<FacebookRedirect />} />
      <Route path="device/:code" element={<DeviceRedirect />} />
    </Routes>
    <Outlet />
  </>
);

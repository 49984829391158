import {
  loadSettings,
  loadSummary,
  saveSettings,
  loadFlights,
  loadFlightById,
  loadStatistic,
  getLoggedFlightTmelines,
  metar,
} from './commonDispatcher';
import { setSessionData, getLoggedInfo } from './base';
import { loadAirframes, loadAirlines, loadFleet, findAirplaneByReg, saveFleet } from './fleet';
import { loadTrack } from './track';
import {
  fetchFlightPlan,
  dispatchFlightPlan,
  authRedirectUrl,
  loadAirports,
  startFlight,
  getFlightStatus,
  importPrefiled,
  lookupAirports,
  cancelFlight,
  completeFlight,
} from './planner';

export const accountTransport = {
  loadSettings,
  loadSummary,
  loadFlights,
  saveSettings,
  loadFlightById,
  loadStatistic,
  getLoggedFlightTmelines,
  metar,
};

export const hangarTransport = {
  loadFleet,
  loadAirlines,
  loadAirframes,
  findAirplaneByReg,
  saveFleet,
};

export const plannerTransport = {
  authRedirectUrl,
  loadAirports,
  dispatchFlightPlan,
  fetchFlightPlan,
  startFlight,
  getFlightStatus,
  importPrefiled,
  lookupAirports,
  cancelFlight,
  completeFlight,
};

export const trackTransport = {
  loadTrack,
};

export { setSessionData, getLoggedInfo };

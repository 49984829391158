import React from 'react';
import { Col, Row } from 'react-bootstrap';

const loadCountrySvg = (iso: string) => {
  const svg = require('assets/img/country/' + iso.toLocaleLowerCase() + '.svg');

  return svg;
};

export const AirportCard = ({ item, compact = false }: { item: any; compact?: boolean }) => (
  <Row>
    <Col sm={compact ? 3 : 6} md={compact ? 3 : 4}>
      <img src={loadCountrySvg(item.country)} alt={item.country} />
    </Col>
    <Col sm={compact ? 9 : 6} md={compact ? 9 : 8}>
      <h5>{item.name}</h5>
      <p className="m-0">
        <strong>{`${item.icao}${item.iata ? ` / ${item.iata}` : ``}`}</strong>
      </p>
      <p className="m-0">{`${item.municipality ? `${item.municipality}, ` : ``}${item.country}`}</p>
    </Col>
  </Row>
);

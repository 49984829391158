import { DataBlock } from 'components/common/DataBlock';
import Divider from 'components/common/Divider';
import { flightData } from 'features/flights';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { formatUnit } from 'utils';

export const FuelBrief = () => {
  const flight = useSelector(flightData);
  const [weights, setWeights] = useState<any>({});
  const [fuel, setFuel] = useState<any>({});

  useEffect(() => {
    setWeights(JSON.parse(flight.weights || '{}'));
    setFuel(JSON.parse(flight.fuel || '{}'));
  }, []);

  return (
    <>
      <Row className="loadSheet">
        <Col xs={6}>
          <DataBlock compact label="Total Burn" value={formatUnit(fuel.burn, flight.units)} />
          <DataBlock compact label="Block Fuel" value={formatUnit(fuel.ramp, flight.units)} />{' '}
          <DataBlock compact nomargin label="Reserve" value={formatUnit(fuel.reserve, flight.units)} />
        </Col>
        <Col xs={6}>
          <DataBlock compact label="Taxi" value={formatUnit(fuel.taxi, flight.units)} />
          <DataBlock compact label="Take Off" value={formatUnit(fuel.takeoff, flight.units)} />
          <DataBlock compact nomargin label="Landing" value={formatUnit(fuel.landing, flight.units)} />
        </Col>
      </Row>
      <Divider />
      <Row className="loadSheet">
        <Col xs={6}>
          <DataBlock compact label="Passengers" value={weights.pax} />
          <DataBlock compact label="Cargo" value={formatUnit(weights.cargo, flight.units)} />
          <DataBlock compact nomargin label="Payload" value={formatUnit(weights.payload, flight.units)} />
        </Col>
        <Col xs={6}>
          <DataBlock compact label="EZFOW" value={formatUnit(weights.estZFW, flight.units)} />
          <DataBlock compact label="ETOW" value={formatUnit(weights.estTOW, flight.units)} />
          <DataBlock compact nomargin label="ELDW" value={formatUnit(weights.estLDW, flight.units)} />
        </Col>
      </Row>
    </>
  );
};

import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

export const Error400 = () => (
  <Card className="text-center">
    <Card.Body className="p-5">
      <div className="display-1 text-300 fs-error">400</div>
      <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
        Bad Request
      </p>
      <hr />
      <p>
        Something went wrong during execution of your request. Please, try again.
      </p>
      <p>
        We are very sorry for any inconvenience.
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon={faHome} className="me-2" />
        Take me home
      </Link>
    </Card.Body>
  </Card>
);

import React from 'react';
import classNames from 'classnames';
import {Background} from './Background';
import { Container } from 'react-bootstrap';

type Props = {
    fluid?: boolean;
    bg?: string;
    image?: string;
    overlay?: string | boolean;
    position?: string;
    video?: [];
    bgClassName?: string;
    className?: string;
    children: React.ReactNode;
};

export const Section = ({
    fluid = false,
    bg,
    image = "",
    overlay = "",
    position,
    video = [],
    bgClassName,
    className,
    children,
    ...rest
}: Props) => {
    const bgProps = { image, overlay, position, video, className: ""};
    bgClassName && (bgProps.className = bgClassName);

    return (
        <section
            className={classNames({ [`bg-${bg}`]: bg }, className)}
            {...rest}
        >
            {image && <Background {...bgProps} />}
            <Container fluid={fluid}>{children}</Container>
        </section>
    );
};

import React, { useEffect, useState } from 'react';
import { accountTransport } from 'transport';

export const Weather = ({ ident }: { ident: string }) => {
  const [data, setData] = useState();

  useEffect(() => {
    accountTransport.metar(ident).then((payload: any) => setData(payload.result));
  }, [data]);

  return (
    <div
      className="mt-4 px-3 py-2"
      style={{ border: '1px solid #c0c0c0', borderRadius: '6px', backgroundColor: '#e2e2e2' }}
    >
      {data}
    </div>
  );
};

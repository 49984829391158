import { PageHeader } from 'components/common/PageHeader';
import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { settings as accountSettings, loadSettings } from 'features/account';
import { accountTransport } from 'transport';

export const StreamingComponent = () => {
  const dispatch = useDispatch();
  const settings = useSelector(accountSettings);
  const [values, setValues] = useState(settings.streaming);

  const { orange, announciators, hdg, spd, alt, vs, orig, dest, dist, trav, airc, airl, reg, call, phs, botText } =
    values;

  const onChange = (field: string, e: any) => {
    let streaming = { ...values };
    if (field === 'botText') {
      streaming[field] = e.target.value;
    } else {
      streaming[field] = !e.target.checked;
    }
    setValues(streaming);
    accountTransport.saveSettings({ ...settings, streaming }).then((payload) => {
      dispatch(loadSettings(payload));
    });
  };

  return (
    <>
      <PageHeader
        title="Streaming Settings"
        description="Easiest way to customize look and feel of stream overlay"
        className="mb-3"
      />

      <Card>
        <Card.Header>
          <h5>Speed / Heading / Altitude Information</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="orange-switch"
              label="Display on the screen"
              checked={!orange}
              onChange={onChange.bind(this, 'orange')}
            />
          </Form>
        </Card.Body>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <Card.Header>
          <h5>Announciators</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="announciators-switch"
              label="Display on the screen"
              checked={!announciators}
              onChange={onChange.bind(this, 'announciators')}
            />
          </Form>
        </Card.Body>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <Card.Header>
          <h5>Top Bar Settings</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={4}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-heading-switch"
                  label="Heading"
                  checked={!hdg}
                  onChange={onChange.bind(this, 'hdg')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-speed-switch"
                  label="Speed"
                  checked={!spd}
                  onChange={onChange.bind(this, 'spd')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-vs-switch"
                  label="Vertical Speed"
                  checked={!vs}
                  onChange={onChange.bind(this, 'vs')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-alt-switch"
                  label="Altitude"
                  checked={!alt}
                  onChange={onChange.bind(this, 'alt')}
                />
              </Col>
              <Col md={4}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-aircraft-switch"
                  label="Aircraft"
                  checked={!airc}
                  onChange={onChange.bind(this, 'airc')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-airline-switch"
                  label="Airline"
                  checked={!airl}
                  onChange={onChange.bind(this, 'airl')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-reg-switch"
                  label="Registration"
                  checked={!reg}
                  onChange={onChange.bind(this, 'reg')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-callsign-switch"
                  label="Callsign"
                  checked={!call}
                  onChange={onChange.bind(this, 'call')}
                />
              </Col>
              <Col md={4}>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-origin-switch"
                  label="Origin"
                  checked={!orig}
                  onChange={onChange.bind(this, 'orig')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-dest-switch"
                  label="Destination"
                  checked={!dest}
                  onChange={onChange.bind(this, 'dest')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-distance-switch"
                  label="Route Distance"
                  checked={!dist}
                  onChange={onChange.bind(this, 'dist')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-travel-distance-switch"
                  label="Traveled Distance"
                  checked={!trav}
                  onChange={onChange.bind(this, 'trav')}
                />
                <Form.Check // prettier-ignore
                  type="switch"
                  id="top-travel-distance-switch"
                  label="Flight Phase"
                  checked={!phs}
                  onChange={onChange.bind(this, 'phs')}
                />
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <Card.Header>
          <h5>Bottom Bar Settings</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Text</Form.Label>
              <Form.Control as="textarea" rows={3} onBlur={onChange.bind(this, 'botText')} defaultValue={botText} />
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
